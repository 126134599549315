import type {
  RumViewEvent,
  RumErrorEvent,
  RumResourceEvent,
  RumFetchResourceEventDomainContext,
  RumInitConfiguration,
  RumErrorEventDomainContext,
  RumViewEventDomainContext,
} from '@datadog/browser-rum-slim'
import { matchPath } from 'react-router-dom'
import BaseError from 'lib/errors/BaseError'
import { stringifyErrorContext } from 'lib/errors/utils'
import type { InitialState } from '../server/utils/initialState'
import { commonRoutes, agencyOnlyRoutes } from '../routesConfig'

export const replaceSensitiveFromUrl = (url = '') =>
  url
    .replace(/email=[^&]*/, 'email=REDACTED')
    .replace(/access_token=[^&]*/, 'access_token=REDACTED')
    .replace(/invite_token=[^&]*/, 'invite_token=REDACTED')
    .replace(/signup\/[^?/&]*/, 'signup/REDACTED')

const findAccountablePartyId = (context: RumViewEventDomainContext): string | null => {
  const agencyRoute = agencyOnlyRoutes.find(route =>
    ([] as string[]).concat(route.path ?? []).some(path =>
      matchPath(context.location.pathname, {
        path,
        exact: true,
        strict: false,
      }),
    ),
  )
  const commonRoute = commonRoutes.find(route =>
    ([] as string[]).concat(route.path ?? []).some(path =>
      matchPath(context.location.pathname, {
        path,
        exact: true,
        strict: false,
      }),
    ),
  )

  const routeConfig = agencyRoute || commonRoute

  if (routeConfig && 'metadata' in routeConfig) {
    return routeConfig.metadata.accountablePartyId
  }

  return null
}

export const beforeSend: RumInitConfiguration['beforeSend'] = (event, context) => {
  if (event.type === 'view') {
    const viewEvent = event as RumViewEvent
    const viewContext = context as RumViewEventDomainContext

    if (!event.context) {
      event.context = {
        globalTags: {},
      }
    }

    const viewAccountablePartyId = findAccountablePartyId(viewContext)

    event.context.globalTags = {
      ...((event.context.globalTags as Record<string, any>) || {}),
      accountable_party_id:
        viewAccountablePartyId || (event.context.globalTags as Record<string, any>)?.accountable_party_id,
    }

    viewEvent.view.url = replaceSensitiveFromUrl(viewEvent.view.url)
  }

  if (event.type === 'resource') {
    const resourceEvent = event as RumResourceEvent
    const resourceContext = context as RumFetchResourceEventDomainContext

    resourceEvent.resource.url = replaceSensitiveFromUrl(resourceEvent.resource.url)

    if (resourceEvent.resource.type === 'fetch') {
      event.context = {
        ...event.context,
        requestId: resourceContext.response?.headers?.get('X-Request-Id'),
      }
    }
  }

  if (event.type === 'error') {
    const errorEvent = event as RumErrorEvent
    const errorContext = context as RumErrorEventDomainContext

    // This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign, app is still working fine
    const errorMessage = event.error.message?.toLowerCase()
    if (
      errorMessage?.includes('resizeobserver loop limit exceeded') ||
      errorMessage?.includes('resizeobserver loop completed with undelivered notifications')
    ) {
      return false
    }

    if (errorEvent.error.source === 'source') {
      if ((errorContext.error as Error) instanceof BaseError) {
        const baseError = errorContext.error as BaseError
        errorEvent.error.message = baseError.toString()
        event.context = {
          ...event.context,
          ...baseError?.context,
          log: stringifyErrorContext(baseError.message, baseError?.context),
        }
      }
    }

    if (errorEvent.error.resource) {
      errorEvent.error.resource.url = replaceSensitiveFromUrl(errorEvent.error.resource.url)
    }
  }

  return true
}

const createDataDogConfig = ({
  version,
  stagingLevel,
  reactAppOverrides,
  isLemma,
  datadogProxy,
}: InitialState): RumInitConfiguration => {
  const sessionSampleRate =
    stagingLevel === 'prod' ? 100 : parseFloat(reactAppOverrides.REACT_APP_DD_RUM_SAMPLE_RATE || '0')
  const traceSampleRate =
    stagingLevel === 'prod' ? 100 : parseFloat(reactAppOverrides.REACT_APP_DD_RUM_TRACING_RATE || '0')

  return {
    service: 'indeed-flex-clients-and-agencies-portal',
    applicationId: '488e2c26-26c6-48d6-a69d-a5aa712b3f68',
    clientToken: 'pubfc3db3eee29dae058dbbd97d80345521',
    env: stagingLevel,
    version: version.releaseVersion, // set on build time in format `1.1.1-ae1edgasd23w4fdg`
    site: 'datadoghq.com',
    beforeSend,
    sessionSampleRate,
    traceSampleRate,
    defaultPrivacyLevel: 'mask-user-input',
    proxy: datadogProxy,
    trackLongTasks: true,
    trackUserInteractions: false,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackViewsManually: true,
    enableExperimentalFeatures: ['feature_flags'],
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    allowFallbackToLocalStorage: true,
    trackSessionAcrossSubdomains: false,
    storeContextsAcrossPages: false,
    allowedTracingUrls: [
      { match: /.*\.qa\.indeed\.net/, propagatorTypes: ['datadog'] },
      { match: /.*\.indeed\.com/, propagatorTypes: ['datadog'] },
    ],
  }
}

export default createDataDogConfig
