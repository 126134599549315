import './publicStaticUrl'
import { datadogRum } from '@datadog/browser-rum-slim'
import BaseError from 'lib/errors/BaseError'
import { initialState } from './config/initialState'
import createDataDogConfig from './config/dataDogConfig'

// Global styles must come before the theme provider
import 'normalize.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import registerServiceWorker from './registerServiceWorker'

const bootstrap = async () => {
  const rootEl = document.getElementById('app-root')
  try {
    // bootstrap lazily to be able to catch and report error to DD
    if (rootEl) {
      const { start } = await import('./bootstrap')
      await start(rootEl)
    } else {
      throw new BaseError('Application Root element not found')
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

registerServiceWorker()

datadogRum.onReady(async () => {
  datadogRum.init(createDataDogConfig(initialState))
  await bootstrap()
})
