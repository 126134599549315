import { fromPairs, compact, identity } from 'lodash'
import { createAction as createSimpleAction } from 'redux-actions'
import uuid from 'uuid'

import { apiActions } from 'api/endpoints'
import { entityTypes } from 'api/schemas'
import { serializeActionType, actionCreatorNameForRequest, requestFlowStates } from 'api/actionTypes'
import * as types from './action-types'

// We annotate all actions in the application with a UUID
// This is the only way to track actions as they get processed
// through various middleware
const metaCreator = (payload, meta) => ({ actionId: uuid(), ...meta })

const createAction = type => createSimpleAction(type, identity, metaCreator)

// The following is an equivalent of doing:
// ```
// export const listing = {
//   fetchListings: createAction(types.LISTINGS_FETCH_BEGIN)
//   fetchListing: createAction(types.LISTING_FETCH_BEGIN)
//   createListing: createAction(types.LISTING_CREATE_BEGIN)
//   updateListing: createAction(types.LISTING_UPDATE_BEGIN)
//   deleteListing: createAction(types.LISTING_DELETE_BEGIN)
// }
// ```
// ...but for every entity type listed in `api/schemas#entityTypes (i.e. every entity
// in Syft's domain).
//
// Appropriate action types need to be defined first in order for the action
// to be defined here
const actions = fromPairs(
  entityTypes.map(entityType => [
    entityType,
    fromPairs(
      compact(
        Object.keys(apiActions).map(apiAction => {
          // beginActionType is e.g. 'LISTINGS_FETCH_BEGIN'
          const beginActionType = serializeActionType({
            entityType,
            apiAction,
            requestFlowState: requestFlowStates.begin,
          })
          if (!types[beginActionType]) return null
          // actionCreatorName is e.g. fetchListings
          const actionCreatorName = actionCreatorNameForRequest(entityType, apiAction)
          const actionCreator = createAction(types[beginActionType])
          return [actionCreatorName, actionCreator]
        }),
      ),
    ),
  ]),
)

// Other actions:

// TODO Compute along begin actions
actions.uniform = {
  ...actions.uniform,
  createUniformSucceeded: createAction(types.UNIFORM_CREATE_SUCCEEDED),
  updateUniformSucceeded: createAction(types.UNIFORM_UPDATE_SUCCEEDED),
}

actions.job = {
  ...actions.job,
  fetchJobSucceeded: createAction(types.JOB_FETCH_SUCCEEDED),
}

actions.auth = {
  login: createAction(types.AUTH_LOGIN_BEGIN),
  refresh: createAction(types.AUTH_REFRESH_BEGIN),
  refreshFailed: createAction(types.AUTH_REFRESH_FAILED),
  forceRefresh: createAction(types.AUTH_REFRESH_FORCE_UPDATE),
  logOut: createAction(types.AUTH_LOG_OUT_BEGIN),
  recoverPassword: createAction(types.AUTH_RECOVER_PASSWORD_BEGIN),
  resetPassword: createAction(types.AUTH_RESET_PASSWORD_BEGIN),
  authCsatSurvey: createAction(types.AUTH_CSAT_SURVEY_CHANGE),
  logOutWarning: createAction(types.AUTH_LOGOUT_WARNING),
  setResetPasswordToken: createAction(types.AUTH_SET_RESET_PASSWORD_TOKEN),
  createWorker: createAction(types.AUTH_CREATE_WORKER_BEGIN),
}

actions.internalWorkerInvitation = {
  ...actions.internalWorkerInvitation,
  resendInternalWorkerInvitation: createAction(types.INTERNAL_WORKER_INVITATION_RESEND_BEGIN),
}

actions.user = {
  ...actions.user,
  changeEmailPassword: createAction(types.USER_CHANGE_EMAIL_PASSWORD_BEGIN),
}

// @param {Object ~ {jobId: [workerId]}} payload, a map of job IDs to arrays
//  of worker IDs
actions.bulkOffer = {
  ...actions.bulkOffer,
  createBulkOffer: actions.bulkOffer.createBulkOffer,
}

actions.joyride = {
  dashboardJoyridePassed: createAction(types.SET_DASHBOARD_JOYRIDE_TOUR_PASSED),
  rotaJoyridePassed: createAction(types.SET_ROTA_JOYRIDE_TOUR_PASSED),
}

actions.rota = {
  ...actions.rota,
  rotaDownloadBooking: createAction(types.ROTA_DOWNLOAD_BOOKING_FETCH_BEGIN),
}

actions.employerTimesheet = {
  ...actions.employerTimesheet,
  employerTimesheetCsvDownload: createAction(types.EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN),
}

actions.agencyTimesheet = {
  ...actions.agencyTimesheet,
  agencyTimesheetCsvDownload: createAction(types.AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN),
}

actions.message = {
  ...actions.message,
  sync: {
    start: createAction(types.MESSAGES_SYNC_START),
    stop: createAction(types.MESSAGES_SYNC_STOP),
  },
}

actions.thread = {
  ...actions.thread,
  sync: {
    start: createAction(types.THREADS_SYNC_START),
    stop: createAction(types.THREADS_SYNC_STOP),
  },
  waitAndMarkAsRead: {
    start: createAction(types.THREAD_MARK_AS_READ_START),
    stop: createAction(types.THREAD_MARK_AS_READ_STOP),
  },
}

actions.notification = {
  ...actions.notification,
  sync: {
    start: createAction(types.NOTIFICATIONS_SYNC_START),
    stop: createAction(types.NOTIFICATIONS_SYNC_STOP),
  },
  markAllAsRead: createAction(types.NOTIFICATION_MARK_ALL_AS_READ_BEGIN),
}

actions.agencyNotification = {
  ...actions.agencyNotification,
  markAllAgencyNotificationsAsRead: createAction(types.AGENCY_NOTIFICATION_MARK_ALL_AS_READ_BEGIN),
}

actions.rating = {
  ...actions.rating,
  updateRatings: createAction(types.RATINGS_UPDATE_BEGIN),
}

actions.platform = {
  changeTheme: createAction(types.PLATFORM_THEME_CHANGE),
  changeVisibility: createAction(types.PLATFORM_VISIBILITY_CHANGE),
}

actions.config = {
  toggleFeature: createAction(types.CONFIG_TOGGLE_FEATURE),
}

actions.paymentSession = {
  ...actions.paymentSession,
  verify: createAction(types.PAYMENT_SESSION_VERIFY_BEGIN),
}

actions.track = {
  ...actions.track,
  emailLink: createAction(types.TRACK_EMAIL_LINK),
  editDeleteShiftsDialogOpen: createAction(types.EDIT_DELETE_SHIFT_DIALOG_OPEN),
  jobSuggestionAccepted: createAction(types.JOB_SUGGESTION_ACCEPTED),
  jobSuggestionDismissed: createAction(types.JOB_SUGGESTION_DISMISSED),
  jobSuggestionReopened: createAction(types.JOB_SUGGESTION_REOPENED),
}

actions.navigation = {
  redirect: createAction(types.NAVIGATION_REDIRECT),
}

actions.csat = {
  ...actions.csatSurvey,
}

actions.agencyTimesheet = {
  ...actions.agencyTimesheet,
  downloadAgencyTimesheet: createAction(types.DOWNLOAD_AGENCY_TIMESHEET_FETCH_BEGIN),
}

actions.screenReaderAlert = {
  setScreenReaderAlert: createAction(types.SET_SCREEN_READER_ALERT),
}

actions.experiments = {
  log: createAction(types.LOG_EXPERIMENT),
}

actions.infoBanner = {
  removeInfoBanner: createAction(types.REMOVE_INFO_BANNER),
}

export default actions
