import BaseError from 'lib/errors/BaseError'
import { humanizeSyftApiCallError } from 'lib/syftApiErrors'
import { datadogRum } from '@datadog/browser-rum-slim'

type ErrorMeta = {
  /**
   * Request URL
   */
  url: string
  /**
   * See Response.status
   */
  status?: number
  /**
   * See Response.statusText
   */
  statusText?: string
  /**
   * X-Request-Id header value
   */
  requestId?: string
}

export type ApiError = {
  /**
   * object with deserialized response body
   */
  body?: Record<string, any>
  /**
   * Fetch Response
   */
  response?: Response
  /**
   * Error from JSON.parse()
   */
  jsonError?: Error
  /**
   * Error from fetch()
   */
  fetchError?: TypeError
  /**
   * Response Meta object
   */
  meta?: ErrorMeta
}

export default class ApiCallError extends BaseError {
  public message: string
  public body?: ApiError['body']
  public response?: ApiError['response']
  public jsonError?: ApiError['jsonError']
  public fetchError?: ApiError['fetchError']
  public meta?: ApiError['meta']

  constructor(error?: ApiError) {
    super()
    Object.assign(this, error)
    this.message = humanizeSyftApiCallError(this, {}) || ''
    this.stack = error?.jsonError?.stack || error?.fetchError?.stack
    this.context = error || {}

    if (this.fetchError || this.jsonError) {
      datadogRum.addError(this?.fetchError || this?.jsonError, {
        message: this.message,
        stack: this.stack,
        context: error,
      })
    }
  }
}
