export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    telephone_number: {
      type: 'string',
      description: 'Phone number',
    },
    level: {
      type: 'string',
      description: 'Account level',
    },
    branch_name: {
      type: 'string',
      description: 'Branch',
    },
    employer_id: {
      type: 'number',
      description: 'Client',
    },
    venue_ids: {
      type: 'array',
      description: 'Location(s)',
    },
    industries: {
      type: 'array',
      description: 'Industries and Roles',
    },
    accounts: {
      type: 'array',
      description: 'Members',
    },
  },
}
