import { initial } from 'lodash'

export const REHYDRATION_COMPLETE = 'REHYDRATION_COMPLETE'

export const SET_DASHBOARD_JOYRIDE_TOUR_PASSED = 'SET_DASHBOARD_JOYRIDE_TOUR_PASSED'
export const SET_ROTA_JOYRIDE_TOUR_PASSED = 'SET_ROTA_JOYRIDE_TOUR_PASSED'

export const SET_JOB_POST_TYPE = 'SET_JOB_POST_TYPE'

export const REMOVE_INFO_BANNER = 'REMOVE_INFO_BANNER'

export const PLATFORM_UPDATE = 'PLATFORM_UPDATE'
export const PLATFORM_THEME_CHANGE = 'PLATFORM_THEME_CHANGE'
export const CONFIG_TOGGLE_FEATURE = 'CONFIG_TOGGLE_FEATURE'
export const PLATFORM_VISIBILITY_CHANGE = 'PLATFORM_VISIBILITY_CHANGE'

export const BATCH_API_ACTION_BEGIN = 'BATCH_API_ACTION_BEGIN'
export const BATCH_API_ACTION_SUCCEEDED = 'BATCH_API_ACTION_SUCCEEDED'
export const BATCH_API_ACTION_FAILED = 'BATCH_API_ACTION_FAILED'

export const ENABLED_FEATURE_FETCH_BEGIN = 'ENABLED_FEATURE_FETCH_BEGIN'
export const ENABLED_FEATURE_FETCH_SUCCEEDED = 'ENABLED_FEATURE_FETCH_SUCCEEDED'
export const ENABLED_FEATURE_FETCH_FAILED = 'ENABLED_FEATURE_FETCH_FAILED'

export const ENABLED_EXPERIMENTS_FETCH_BEGIN = 'ENABLED_EXPERIMENTS_FETCH_BEGIN'
export const ENABLED_EXPERIMENTS_FETCH_SUCCEEDED = 'ENABLED_EXPERIMENTS_FETCH_SUCCEEDED'
export const ENABLED_EXPERIMENTS_FETCH_FAILED = 'ENABLED_EXPERIMENTS_FETCH_FAILED'

export const CSAT_SURVEY_FETCH_BEGIN = 'CSAT_SURVEY_FETCH_BEGIN'
export const CSAT_SURVEY_FETCH_SUCCEEDED = 'CSAT_SURVEY_FETCH_SUCCEEDED'
export const CSAT_SURVEY_FETCH_FAILED = 'CSAT_SURVEY_FETCH_FAILED'

export const ADDRESS_TIMEZONE_FETCH_BEGIN = 'ADDRESS_TIMEZONE_FETCH_BEGIN'
export const ADDRESS_TIMEZONE_FETCH_SUCCEEDED = 'ADDRESS_TIMEZONE_FETCH_SUCCEEDED'
export const ADDRESS_TIMEZONE_FETCH_FAILED = 'ADDRESS_TIMEZONE_FETCH_FAILED'

export const SUGGESTION_FETCH_BEGIN = 'SUGGESTION_FETCH_BEGIN'
export const SUGGESTION_FETCH_SUCCEEDED = 'SUGGESTION_FETCH_SUCCEEDED'
export const SUGGESTION_FETCH_FAILED = 'SUGGESTION_FETCH_FAILED'
export const SUGGESTION_DELETE_BEGIN = 'SUGGESTION_DELETE_BEGIN'
export const SUGGESTION_DELETE_SUCCEEDED = 'SUGGESTION_DELETE_SUCCEEDED'
export const SUGGESTION_DELETE_FAILED = 'SUGGESTION_DELETE_FAILED'

export const CSAT_SURVEY_CREATE_SUCCEEDED = 'CSAT_SURVEY_CREATE_SUCCEEDED'
export const CSAT_SURVEY_CREATE_FAILED = 'CSAT_SURVEY_CREATE_FAILED'
export const CSAT_SURVEY_CREATE_BEGIN = 'CSAT_SURVEY_CREATE_BEGIN'

export const AUTH_LOGIN_BEGIN = 'AUTH_LOGIN_BEGIN'
export const AUTH_LOGIN_SUCCEEDED = 'AUTH_LOGIN_SUCCEEDED'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT'
export const AUTH_LOG_OUT_BEGIN = 'AUTH_LOG_OUT_BEGIN'
export const AUTH_RECOVER_PASSWORD_BEGIN = 'AUTH_RECOVER_PASSWORD_BEGIN'
export const AUTH_RECOVER_PASSWORD_SUCCEEDED = 'AUTH_RECOVER_PASSWORD_SUCCEEDED'
export const AUTH_RECOVER_PASSWORD_FAILED = 'AUTH_RECOVER_PASSWORD_FAILED'
export const AUTH_RESET_PASSWORD_BEGIN = 'AUTH_RESET_PASSWORD_BEGIN'
export const AUTH_RESET_PASSWORD_SUCCEEDED = 'AUTH_RESET_PASSWORD_SUCCEEDED'
export const AUTH_RESET_PASSWORD_FAILED = 'AUTH_RESET_PASSWORD_FAILED'
export const AUTH_REFRESH_BEGIN = 'AUTH_REFRESH_BEGIN'
export const AUTH_REFRESH_SUCCEEDED = 'AUTH_REFRESH_SUCCEEDED'
export const AUTH_REFRESH_FAILED = 'AUTH_REFRESH_FAILED'
export const AUTH_REFRESH_FORCE_UPDATE = 'AUTH_REFRESH_FORCE_UPDATE'
export const AUTH_TOGGLE_INTERNAL_RESOURCING = 'AUTH_TOGGLE_INTERNAL_RESOURCING'
export const AUTH_USERDATA_UPDATE = 'AUTH_USERDATA_UPDATE'
export const AUTH_CSAT_SURVEY_CHANGE = 'AUTH_CSAT_SURVEY_CHANGE'
export const AUTH_LOGOUT_WARNING = 'AUTH_LOGOUT_WARNING'

// Partially handled in reducers/auth
export const CURRENT_USER_FETCH_BEGIN = 'CURRENT_USER_FETCH_BEGIN'
export const CURRENT_USER_FETCH_SUCCEEDED = 'CURRENT_USER_FETCH_SUCCEEDED'
export const CURRENT_USER_FETCH_FAILED = 'CURRENT_USER_FETCH_FAILED'

export const USER_CHAT_VERIFICATION_FETCH_BEGIN = 'USER_CHAT_VERIFICATION_FETCH_BEGIN'
export const USER_CHAT_VERIFICATION_FETCH_SUCCEEDED = 'USER_CHAT_VERIFICATION_FETCH_SUCCEEDED'
export const USER_CHAT_VERIFICATION_FETCH_FAILED = 'USER_CHAT_VERIFICATION_FETCH_FAILED'

// Image upload
export const IMAGE_UPDATE_BEGIN = 'IMAGE_UPDATE_BEGIN'
export const IMAGE_UPDATE_SUCCEEDED = 'IMAGE_UPDATE_SUCCEEDED'
export const IMAGE_UPDATE_FAILED = 'IMAGE_UPDATE_FAILED'
// Timesheet File upload
export const TIMESHEET_FILE_CREATE_BEGIN = 'TIMESHEET_FILE_CREATE_BEGIN'
export const TIMESHEET_FILE_CREATE_SUCCEEDED = 'TIMESHEET_FILE_CREATE_SUCCEEDED'
export const TIMESHEET_FILE_CREATE_FAILED = 'TIMESHEET_FILE_CREATE_FAILED'

export const LISTING_CREATE_BEGIN = 'LISTING_CREATE_BEGIN'
export const LISTING_CREATE_SUCCEEDED = 'LISTING_CREATE_SUCCEEDED'
export const LISTING_CREATE_FAILED = 'LISTING_CREATE_FAILED'
export const LISTING_FETCH_BEGIN = 'LISTING_FETCH_BEGIN'
export const LISTING_FETCH_SUCCEEDED = 'LISTING_FETCH_SUCCEEDED'
export const LISTING_FETCH_FAILED = 'LISTING_FETCH_FAILED'
export const LISTINGS_FETCH_BEGIN = 'LISTINGS_FETCH_BEGIN'
export const LISTINGS_FETCH_SUCCEEDED = 'LISTINGS_FETCH_SUCCEEDED'
export const LISTINGS_FETCH_FAILED = 'LISTINGS_FETCH_FAILED'

export const SCHEDULES_FETCH_BEGIN = 'SCHEDULES_FETCH_BEGIN'
export const SCHEDULES_FETCH_SUCCEEDED = 'SCHEDULES_FETCH_SUCCEEDED'
export const SCHEDULES_FETCH_FAILED = 'SCHEDULES_FETCH_FAILED'
export const SCHEDULES_INVALIDATE = 'SCHEDULES_INVALIDATE'

export const SCHEDULE_MONTHS_FETCH_BEGIN = 'SCHEDULE_MONTHS_FETCH_BEGIN'
export const SCHEDULE_MONTHS_FETCH_SUCCEEDED = 'SCHEDULE_MONTHS_FETCH_SUCCEEDED'
export const SCHEDULE_MONTHS_FETCH_FAILED = 'SCHEDULE_MONTHS_FETCH_FAILED'
export const SCHEDULE_MONTHS_INVALIDATE = 'SCHEDULE_MONTHS_INVALIDATE'

export const INSIGHT_FETCH_BEGIN = 'INSIGHT_FETCH_BEGIN'
export const INSIGHT_FETCH_SUCCEEDED = 'INSIGHT_FETCH_SUCCEEDED'
export const INSIGHT_FETCH_FAILED = 'INSIGHT_FETCH_FAILED'
export const INSIGHT_INVALIDATE = 'INSIGHT_INVALIDATE'

export const SCHEDULE_ALLS_FETCH_BEGIN = 'SCHEDULE_ALLS_FETCH_BEGIN'
export const SCHEDULE_ALLS_FETCH_SUCCEEDED = 'SCHEDULE_ALLS_FETCH_SUCCEEDED'
export const SCHEDULE_ALLS_FETCH_FAILED = 'SCHEDULE_ALLS_FETCH_FAILED'
export const SCHEDULE_ALLS_INVALIDATE = 'SCHEDULE_ALLS_INVALIDATE'

export const LISTING_UPDATE_BEGIN = 'LISTING_UPDATE_BEGIN'
export const LISTING_UPDATE_SUCCEEDED = 'LISTING_UPDATE_SUCCEEDED'
export const LISTING_UPDATE_FAILED = 'LISTING_UPDATE_FAILED'
export const LISTING_DELETE_BEGIN = 'LISTING_DELETE_BEGIN'
export const LISTING_DELETE_SUCCEEDED = 'LISTING_DELETE_SUCCEEDED'
export const LISTING_DELETE_FAILED = 'LISTING_DELETE_FAILED'
export const LISTING_INVALIDATE = 'LISTING_INVALIDATE'

export const FULFILMENT_SUGGESTION_CREATE_BEGIN = 'FULFILMENT_SUGGESTION_CREATE_BEGIN'
export const FULFILMENT_SUGGESTION_CREATE_SUCCEEDED = 'FULFILMENT_SUGGESTION_CREATE_SUCCEEDED'
export const FULFILMENT_SUGGESTION_CREATE_FAILED = 'FULFILMENT_SUGGESTION_CREATE_FAILED'
export const FULFILMENT_SUGGESTION_THEN_LISTING_UPDATE_BEGIN =
  'FULFILMENT_SUGGESTION_THEN_LISTING_UPDATE_BEGIN'

export const LISTING_OVERVIEW_FETCH_BEGIN = 'LISTING_OVERVIEW_FETCH_BEGIN'
export const LISTING_OVERVIEW_FETCH_SUCCEEDED = 'LISTING_OVERVIEW_FETCH_SUCCEEDED'
export const LISTING_OVERVIEW_FETCH_FAILED = 'LISTING_OVERVIEW_FETCH_FAILED'

export const LISTING_REASON_CREATE_BEGIN = 'LISTING_REASON_CREATE_BEGIN'
export const LISTING_REASON_CREATE_SUCCEEDED = 'LISTING_REASON_CREATE_SUCCEEDED'
export const LISTING_REASON_CREATE_FAILED = 'LISTING_REASON_CREATE_FAILED'
export const LISTING_REASON_DELETE_BEGIN = 'LISTING_REASON_DELETE_BEGIN'
export const LISTING_REASON_DELETE_SUCCEEDED = 'LISTING_REASON_DELETE_SUCCEEDED'
export const LISTING_REASON_DELETE_FAILED = 'LISTING_REASON_DELETE_FAILED'

export const LISTING_AGENCIES_FETCH_BEGIN = 'LISTING_AGENCIES_FETCH_BEGIN'
export const LISTING_AGENCIES_FETCH_SUCCEEDED = 'LISTING_AGENCIES_FETCH_SUCCEEDED'
export const LISTING_AGENCIES_FETCH_FAILED = 'LISTING_AGENCIES_FETCH_FAILED'

export const LISTING_AGENCY_FETCH_BEGIN = 'LISTING_AGENCY_FETCH_BEGIN'
export const LISTING_AGENCY_FETCH_SUCCEEDED = 'LISTING_AGENCY_FETCH_SUCCEEDED'
export const LISTING_AGENCY_FETCH_FAILED = 'LISTING_AGENCY_FETCH_FAILED'

export const LISTING_PAY_RATES_FETCH_BEGIN = 'LISTING_PAY_RATES_FETCH_BEGIN'
export const LISTING_PAY_RATES_FETCH_SUCCEEDED = 'LISTING_PAY_RATES_FETCH_SUCCEEDED'
export const LISTING_PAY_RATES_FETCH_FAILED = 'LISTING_PAY_RATES_FETCH_FAILED'

export const LISTING_AGENCY_EMAIL_CREATE_BEGIN = 'LISTING_AGENCY_EMAIL_CREATE_BEGIN'
export const LISTING_AGENCY_EMAIL_CREATE_SUCCEEDED = 'LISTING_AGENCY_EMAIL_CREATE_SUCCEEDED'
export const LISTING_AGENCY_EMAIL_CREATE_FAILED = 'LISTING_AGENCY_EMAIL_CREATE_FAILED'

export const LISTING_AGENCY_DELETE_BEGIN = 'LISTING_AGENCY_DELETE_BEGIN'
export const LISTING_AGENCY_DELETE_SUCCEEDED = 'LISTING_AGENCY_DELETE_SUCCEEDED'
export const LISTING_AGENCY_DELETE_FAILED = 'LISTING_AGENCY_DELETE_FAILED'

export const AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_BEGIN = 'AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_BEGIN'
export const AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_SUCCEEDED =
  'AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_SUCCEEDED'
export const AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_FAILED = 'AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_FAILED'

export const AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_BEGIN = 'AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_BEGIN'
export const AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_SUCCEEDED = 'AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_SUCCEEDED'
export const AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_FAILED = 'AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_FAILED'

export const AGENCY_LISTING_JOB_CARD_CREATE_BEGIN = 'AGENCY_LISTING_JOB_CARD_CREATE_BEGIN'
export const AGENCY_LISTING_JOB_CARD_CREATE_SUCCEEDED = 'AGENCY_LISTING_JOB_CARD_CREATE_SUCCEEDED'
export const AGENCY_LISTING_JOB_CARD_CREATE_FAILED = 'AGENCY_LISTING_JOB_CARD_CREATE_FAILED'

export const AGENCY_ALLOCATIONS_FETCH_BEGIN = 'AGENCY_ALLOCATIONS_FETCH_BEGIN'
export const AGENCY_ALLOCATIONS_FETCH_SUCCEEDED = 'AGENCY_ALLOCATIONS_FETCH_SUCCEEDED'
export const AGENCY_ALLOCATIONS_FETCH_FAILED = 'AGENCY_ALLOCATIONS_FETCH_FAILED'

export const AGENCY_CLIENTS_FETCH_SUCCEEDED = 'AGENCY_CLIENTS_FETCH_SUCCEEDED'
export const AGENCY_CLIENTS_FETCH_FAILED = 'AGENCY_CLIENTS_FETCH_FAILED'
export const AGENCY_CLIENTS_FETCH_BEGIN = 'AGENCY_CLIENTS_FETCH_BEGIN'

export const AGENCY_PAY_RATES_FETCH_SUCCEEDED = 'AGENCY_PAY_RATES_FETCH_SUCCEEDED'
export const AGENCY_PAY_RATES_FETCH_FAILED = 'AGENCY_PAY_RATES_FETCH_FAILED'
export const AGENCY_PAY_RATES_FETCH_BEGIN = 'AGENCY_PAY_RATES_FETCH_BEGIN'

export const AGENCY_ALLOCATION_UPDATE_BEGIN = 'AGENCY_ALLOCATION_UPDATE_BEGIN'
export const AGENCY_ALLOCATION_UPDATE_SUCCEEDED = 'AGENCY_ALLOCATION_UPDATE_SUCCEEDED'
export const AGENCY_ALLOCATION_UPDATE_FAILED = 'AGENCY_ALLOCATION_UPDATE_FAILED'

export const AGENCY_ALLOCATIONS_UPDATE_SUCCEEDED = 'AGENCY_ALLOCATIONS_UPDATE_SUCCEEDED'

export const DOWNLOAD_AGENCY_TIMESHEET_FETCH_BEGIN = 'DOWNLOAD_AGENCY_TIMESHEET_FETCH_BEGIN'
export const DOWNLOAD_AGENCY_TIMESHEET_FETCH_SUCCEEDED = 'DOWNLOAD_AGENCY_TIMESHEET_FETCH_SUCCEEDED'
export const DOWNLOAD_AGENCY_TIMESHEET_FETCH_FAILED = 'DOWNLOAD_AGENCY_TIMESHEET_FETCH_FAILED'

export const AGENCY_LISTINGS_FETCH_SUCCEEDED = 'AGENCY_LISTINGS_FETCH_SUCCEEDED'
export const AGENCY_LISTINGS_FETCH_FAILED = 'AGENCY_LISTINGS_FETCH_FAILED'
export const AGENCY_LISTINGS_FETCH_BEGIN = 'AGENCY_LISTINGS_FETCH_BEGIN'
export const AGENCY_LISTING_FETCH_SUCCEEDED = 'AGENCY_LISTING_FETCH_SUCCEEDED'
export const AGENCY_LISTING_FETCH_FAILED = 'AGENCY_LISTING_FETCH_FAILED'
export const AGENCY_LISTING_FETCH_BEGIN = 'AGENCY_LISTING_FETCH_BEGIN'

export const AGENCY_VENUES_AND_ROLES_FETCH_SUCCEEDED = 'AGENCY_VENUES_AND_ROLES_FETCH_SUCCEEDED'
export const AGENCY_VENUES_AND_ROLES_FETCH_FAILED = 'AGENCY_VENUES_AND_ROLES_FETCH_FAILED'
export const AGENCY_VENUES_AND_ROLES_FETCH_BEGIN = 'AGENCY_VENUES_AND_ROLES_FETCH_BEGIN'

export const AGENCY_PROVISIONAL_COSTS_FETCH_SUCCEEDED = 'AGENCY_PROVISIONAL_COSTS_FETCH_SUCCEEDED'
export const AGENCY_PROVISIONAL_COSTS_FETCH_FAILED = 'AGENCY_PROVISIONAL_COSTS_FETCH_FAILED'
export const AGENCY_PROVISIONAL_COSTS_FETCH_BEGIN = 'AGENCY_PROVISIONAL_COSTS_FETCH_BEGIN'

export const AGENCY_LISTING_UPDATE_SUCCEEDED = 'AGENCY_LISTING_UPDATE_SUCCEEDED'

export const AGENCY_JOB_FETCH_SUCCEEDED = 'AGENCY_JOB_FETCH_SUCCEEDED'
export const AGENCY_JOB_FETCH_FAILED = 'AGENCY_JOB_FETCH_FAILED'
export const AGENCY_JOB_FETCH_BEGIN = 'AGENCY_JOB_FETCH_BEGIN'

export const AGENCY_JOB_DETAIL_FETCH_SUCCEEDED = 'AGENCY_JOB_DETAIL_FETCH_SUCCEEDED'
export const AGENCY_JOB_DETAIL_FETCH_FAILED = 'AGENCY_JOB_DETAIL_FETCH_FAILED'
export const AGENCY_JOB_DETAIL_FETCH_BEGIN = 'AGENCY_JOB_DETAIL_FETCH_BEGIN'

export const AGENCY_JOB_DETAIL_CREATE_SUCCEEDED = 'AGENCY_JOB_DETAIL_CREATE_SUCCEEDED'
export const AGENCY_JOB_DETAIL_CREATE_FAILED = 'AGENCY_JOB_DETAIL_CREATE_FAILED'
export const AGENCY_JOB_DETAIL_CREATE_BEGIN = 'AGENCY_JOB_DETAIL_CREATE_BEGIN'

export const AGENCY_JOB_OVERVIEW_FETCH_SUCCEEDED = 'AGENCY_JOB_OVERVIEW_FETCH_SUCCEEDED'
export const AGENCY_JOB_OVERVIEW_FETCH_FAILED = 'AGENCY_JOB_OVERVIEW_FETCH_FAILED'
export const AGENCY_JOB_OVERVIEW_FETCH_BEGIN = 'AGENCY_JOB_OVERVIEW_FETCH_BEGIN'

export const AGENCY_JOBS_FETCH_SUCCEEDED = 'AGENCY_JOBS_FETCH_SUCCEEDED'
export const AGENCY_JOBS_FETCH_FAILED = 'AGENCY_JOBS_FETCH_FAILED'
export const AGENCY_JOBS_FETCH_BEGIN = 'AGENCY_JOBS_FETCH_BEGIN'

export const AGENCY_WORKER_BOOKING_CREATE_SUCCEEDED = 'AGENCY_WORKER_BOOKING_CREATE_SUCCEEDED'
export const AGENCY_WORKER_BOOKING_CREATE_FAILED = 'AGENCY_WORKER_BOOKING_CREATE_FAILED'
export const AGENCY_WORKER_BOOKING_CREATE_BEGIN = 'AGENCY_WORKER_BOOKING_CREATE_BEGIN'

export const AGENCY_WORKER_BOOKING_DELETE_SUCCEEDED = 'AGENCY_WORKER_BOOKING_DELETE_SUCCEEDED'
export const AGENCY_WORKER_BOOKING_DELETE_FAILED = 'AGENCY_WORKER_BOOKING_DELETE_FAILED'
export const AGENCY_WORKER_BOOKING_DELETE_BEGIN = 'AGENCY_WORKER_BOOKING_DELETE_BEGIN'

export const AGENCY_ALLOCATION_WORKERS_FETCH_BEGIN = 'AGENCY_ALLOCATION_WORKERS_FETCH_BEGIN'
export const AGENCY_ALLOCATION_WORKERS_FETCH_SUCCEEDED = 'AGENCY_ALLOCATION_WORKERS_FETCH_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKERS_FETCH_FAILED = 'AGENCY_ALLOCATION_WORKERS_FETCH_FAILED'
export const AGENCY_ALLOCATION_WORKER_FETCH_BEGIN = 'AGENCY_ALLOCATION_WORKER_FETCH_BEGIN'
export const AGENCY_ALLOCATION_WORKER_FETCH_SUCCEEDED = 'AGENCY_ALLOCATION_WORKER_FETCH_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKER_FETCH_FAILED = 'AGENCY_ALLOCATION_WORKER_FETCH_FAILED'
export const AGENCY_ALLOCATION_WORKER_CREATE_BEGIN = 'AGENCY_ALLOCATION_WORKER_CREATE_BEGIN'
export const AGENCY_ALLOCATION_WORKER_CREATE_SUCCEEDED = 'AGENCY_ALLOCATION_WORKER_CREATE_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKER_CREATE_FAILED = 'AGENCY_ALLOCATION_WORKER_CREATE_FAILED'
export const AGENCY_ALLOCATION_WORKER_UPDATE_BEGIN = 'AGENCY_ALLOCATION_WORKER_UPDATE_BEGIN'
export const AGENCY_ALLOCATION_WORKER_UPDATE_SUCCEEDED = 'AGENCY_ALLOCATION_WORKER_UPDATE_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKER_UPDATE_FAILED = 'AGENCY_ALLOCATION_WORKER_UPDATE_FAILED'
export const AGENCY_ALLOCATION_WORKER_DELETE_BEGIN = 'AGENCY_ALLOCATION_WORKER_DELETE_BEGIN'
export const AGENCY_ALLOCATION_WORKER_DELETE_SUCCEEDED = 'AGENCY_ALLOCATION_WORKER_DELETE_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKER_DELETE_FAILED = 'AGENCY_ALLOCATION_WORKER_DELETE_FAILED'
export const AGENCY_ALLOCATION_WORKERS_CREATE_SUCCEEDED = 'AGENCY_ALLOCATION_WORKERS_CREATE_SUCCEEDED'
export const AGENCY_ALLOCATION_WORKERS_UPDATE_SUCCEEDED = 'AGENCY_ALLOCATION_WORKERS_UPDATE_SUCCEEDED'

export const APPROVE_LISTINGS_FETCH_SUCCEEDED = 'APPROVE_LISTINGS_FETCH_SUCCEEDED'
export const APPROVE_LISTINGS_FETCH_FAILED = 'APPROVE_LISTINGS_FETCH_FAILED'
export const APPROVE_LISTINGS_FETCH_BEGIN = 'APPROVE_LISTINGS_FETCH_BEGIN'

export const LISTING_TEMPLATE_FETCH_BEGIN = 'LISTING_TEMPLATE_FETCH_BEGIN'
export const LISTING_TEMPLATE_FETCH_SUCCEEDED = 'LISTING_TEMPLATE_FETCH_SUCCEEDED'
export const LISTING_TEMPLATE_FETCH_FAILED = 'LISTING_TEMPLATE_FETCH_FAILED'
export const LISTING_TEMPLATE_UPDATE_BEGIN = 'LISTING_TEMPLATE_UPDATE_BEGIN'
export const LISTING_TEMPLATE_UPDATE_SUCCEEDED = 'LISTING_TEMPLATE_UPDATE_SUCCEEDED'
export const LISTING_TEMPLATE_UPDATE_FAILED = 'LISTING_TEMPLATE_UPDATE_FAILED'
export const LISTING_TEMPLATE_CREATE_BEGIN = 'LISTING_TEMPLATE_CREATE_BEGIN'
export const LISTING_TEMPLATE_CREATE_SUCCEEDED = 'LISTING_TEMPLATE_CREATE_SUCCEEDED'
export const LISTING_TEMPLATE_CREATE_FAILED = 'LISTING_TEMPLATE_CREATE_FAILED'
export const LISTING_TEMPLATE_DELETE_BEGIN = 'LISTING_TEMPLATE_DELETE_BEGIN'
export const LISTING_TEMPLATE_DELETE_SUCCEEDED = 'LISTING_TEMPLATE_DELETE_SUCCEEDED'
export const LISTING_TEMPLATE_DELETE_FAILED = 'LISTING_TEMPLATE_DELETE_FAILED'
export const LISTING_TEMPLATES_FETCH_BEGIN = 'LISTING_TEMPLATES_FETCH_BEGIN'
export const LISTING_TEMPLATES_FETCH_SUCCEEDED = 'LISTING_TEMPLATES_FETCH_SUCCEEDED'
export const LISTING_TEMPLATES_FETCH_FAILED = 'LISTING_TEMPLATES_FETCH_FAILED'

export const AGENCY_TIMESHEETS_FETCH_SUCCEEDED = 'AGENCY_TIMESHEETS_FETCH_SUCCEEDED'
export const AGENCY_TIMESHEETS_FETCH_FAILED = 'AGENCY_TIMESHEETS_FETCH_FAILED'
export const AGENCY_TIMESHEETS_FETCH_BEGIN = 'AGENCY_TIMESHEETS_FETCH_BEGIN'

export const AGENCY_TIMESHEET_UPDATE_SUCCEEDED = 'AGENCY_TIMESHEET_UPDATE_SUCCEEDED'
export const AGENCY_TIMESHEET_UPDATE_FAILED = 'AGENCY_TIMESHEET_UPDATE_FAILED'
export const AGENCY_TIMESHEET_UPDATE_BEGIN = 'AGENCY_TIMESHEET_UPDATE_BEGIN'

export const AGENCY_TIMESHEET_CREATE_SUCCEEDED = 'AGENCY_TIMESHEET_CREATE_SUCCEEDED'
export const AGENCY_TIMESHEET_CREATE_FAILED = 'AGENCY_TIMESHEET_CREATE_FAILED'
export const AGENCY_TIMESHEET_CREATE_BEGIN = 'AGENCY_TIMESHEET_CREATE_BEGIN'

export const AGENCY_TIMESHEET_COUNT_UPDATE_BEGIN = 'AGENCY_TIMESHEET_COUNT_UPDATE_BEGIN'
export const AGENCY_TIMESHEET_COUNT_UPDATE_SUCCEEDED = 'AGENCY_TIMESHEET_COUNT_UPDATE_SUCCEEDED'
export const AGENCY_TIMESHEET_COUNT_UPDATE_FAILED = 'AGENCY_TIMESHEET_COUNT_UPDATE_FAILED'

export const AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_BEGIN = 'AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_BEGIN'
export const AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_SUCCEEDED =
  'AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_SUCCEEDED'
export const AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_FAILED = 'AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_FAILED'

export const AGENCY_TIMESHEET_COUNT_FETCH_BEGIN = 'AGENCY_TIMESHEET_COUNT_FETCH_BEGIN'
export const AGENCY_TIMESHEET_COUNT_FETCH_SUCCEEDED = 'AGENCY_TIMESHEET_COUNT_FETCH_SUCCEEDED'
export const AGENCY_TIMESHEET_COUNT_FETCH_FAILED = 'AGENCY_TIMESHEET_COUNT_FETCH_FAILED'

export const EMPLOYER_ACCOUNTS_FETCH_BEGIN = 'EMPLOYER_ACCOUNTS_FETCH_BEGIN'
export const EMPLOYER_ACCOUNTS_FETCH_SUCCEEDED = 'EMPLOYER_ACCOUNTS_FETCH_SUCCEEDED'
export const EMPLOYER_ACCOUNTS_FETCH_FAILED = 'EMPLOYER_ACCOUNTS_FETCH_FAILED'
export const EMPLOYER_ACCOUNT_UPDATE_BEGIN = 'EMPLOYER_ACCOUNT_UPDATE_BEGIN'
export const EMPLOYER_ACCOUNT_UPDATE_SUCCEEDED = 'EMPLOYER_ACCOUNT_UPDATE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_UPDATE_FAILED = 'EMPLOYER_ACCOUNT_UPDATE_FAILED'
export const EMPLOYER_ACCOUNT_CREATE_BEGIN = 'EMPLOYER_ACCOUNT_CREATE_BEGIN'
export const EMPLOYER_ACCOUNT_CREATE_SUCCEEDED = 'EMPLOYER_ACCOUNT_CREATE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_CREATE_FAILED = 'EMPLOYER_ACCOUNT_CREATE_FAILED'
export const EMPLOYER_ACCOUNT_DELETE_BEGIN = 'EMPLOYER_ACCOUNT_DELETE_BEGIN'
export const EMPLOYER_ACCOUNT_DELETE_SUCCEEDED = 'EMPLOYER_ACCOUNT_DELETE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_DELETE_FAILED = 'EMPLOYER_ACCOUNT_DELETE_FAILED'

export const EMPLOYER_ACCOUNT_INVITATION_UPDATE_BEGIN = 'EMPLOYER_ACCOUNT_INVITATION_UPDATE_BEGIN'
export const EMPLOYER_ACCOUNT_INVITATION_UPDATE_SUCCEEDED = 'EMPLOYER_ACCOUNT_INVITATION_UPDATE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_INVITATION_UPDATE_FAILED = 'EMPLOYER_ACCOUNT_INVITATION_UPDATE_FAILED'

export const EMPLOYER_ACCOUNT_INVITATION_CREATE_BEGIN = 'EMPLOYER_ACCOUNT_INVITATION_CREATE_BEGIN'
export const EMPLOYER_ACCOUNT_INVITATION_CREATE_SUCCEEDED = 'EMPLOYER_ACCOUNT_INVITATION_CREATE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_INVITATION_CREATE_FAILED = 'EMPLOYER_ACCOUNT_INVITATION_CREATE_FAILED'

export const EMPLOYER_ACCOUNT_INVITATION_FETCH_BEGIN = 'EMPLOYER_ACCOUNT_INVITATION_FETCH_BEGIN'
export const EMPLOYER_ACCOUNT_INVITATION_FETCH_SUCCEEDED = 'EMPLOYER_ACCOUNT_INVITATION_FETCH_SUCCEEDED'
export const EMPLOYER_ACCOUNT_INVITATION_FETCH_FAILED = 'EMPLOYER_ACCOUNT_INVITATION_FETCH_FAILED'

export const BOOKING_STAT_FETCH_BEGIN = 'BOOKING_STAT_FETCH_BEGIN'
export const BOOKING_STAT_FETCH_SUCCEEDED = 'BOOKING_STAT_FETCH_SUCCEEDED'
export const BOOKING_STAT_FETCH_FAILED = 'BOOKING_STAT_FETCH_FAILED'
export const BOOKING_STATS_INVALIDATE = 'BOOKING_STATS_INVALIDATE'

export const TOTAL_WORKED_HOUR_FETCH_BEGIN = 'TOTAL_WORKED_HOUR_FETCH_BEGIN'
export const TOTAL_WORKED_HOUR_FETCH_SUCCEEDED = 'TOTAL_WORKED_HOUR_FETCH_SUCCEEDED'
export const TOTAL_WORKED_HOUR_FETCH_FAILED = 'TOTAL_WORKED_HOUR_FETCH_FAILED'
export const TOTAL_WORKED_HOURS_INVALIDATE = 'TOTAL_WORKED_HOURS_INVALIDATE'

export const TOTAL_FILLED_SHIFT_FETCH_BEGIN = 'TOTAL_FILLED_SHIFT_FETCH_BEGIN'
export const TOTAL_FILLED_SHIFT_FETCH_SUCCEEDED = 'TOTAL_FILLED_SHIFT_FETCH_SUCCEEDED'
export const TOTAL_FILLED_SHIFT_FETCH_FAILED = 'TOTAL_FILLED_SHIFT_FETCH_FAILED'
export const TOTAL_FILLED_SHIFTS_INVALIDATE = 'TOTAL_FILLED_SHIFTS_INVALIDATE'

export const STAFF_AVERAGE_RATING_FETCH_BEGIN = 'STAFF_AVERAGE_RATING_FETCH_BEGIN'
export const STAFF_AVERAGE_RATING_FETCH_SUCCEEDED = 'STAFF_AVERAGE_RATING_FETCH_SUCCEEDED'
export const STAFF_AVERAGE_RATING_FETCH_FAILED = 'STAFF_AVERAGE_RATING_FETCH_FAILED'
export const STAFF_AVERAGE_RATINGS_INVALIDATE = 'STAFF_AVERAGE_RATINGS_INVALIDATE'

export const VENUE_AVERAGE_RATING_FETCH_BEGIN = 'VENUE_AVERAGE_RATING_FETCH_BEGIN'
export const VENUE_AVERAGE_RATING_FETCH_SUCCEEDED = 'VENUE_AVERAGE_RATING_FETCH_SUCCEEDED'
export const VENUE_AVERAGE_RATING_FETCH_FAILED = 'VENUE_AVERAGE_RATING_FETCH_FAILED'
export const VENUE_AVERAGE_RATINGS_INVALIDATE = 'VENUE_AVERAGE_RATINGS_INVALIDATE'

export const TOTAL_ESTIMATED_COST_FETCH_BEGIN = 'TOTAL_ESTIMATED_COST_FETCH_BEGIN'
export const TOTAL_ESTIMATED_COST_FETCH_SUCCEEDED = 'TOTAL_ESTIMATED_COST_FETCH_SUCCEEDED'
export const TOTAL_ESTIMATED_COST_FETCH_FAILED = 'TOTAL_ESTIMATED_COST_FETCH_FAILED'
export const TOTAL_ESTIMATED_COSTS_INVALIDATE = 'TOTAL_ESTIMATED_COSTS_INVALIDATE'

export const COST_BREAKDOWN_FETCH_BEGIN = 'COST_BREAKDOWN_FETCH_BEGIN'
export const COST_BREAKDOWN_FETCH_SUCCEEDED = 'COST_BREAKDOWN_FETCH_SUCCEEDED'
export const COST_BREAKDOWN_FETCH_FAILED = 'COST_BREAKDOWN_FETCH_FAILED'
export const COST_BREAKDOWNS_INVALIDATE = 'COST_BREAKDOWNS_INVALIDATE'

export const WORKER_NO_SHOW_FETCH_BEGIN = 'WORKER_NO_SHOW_FETCH_BEGIN'
export const WORKER_NO_SHOW_FETCH_SUCCEEDED = 'WORKER_NO_SHOW_FETCH_SUCCEEDED'
export const WORKER_NO_SHOW_FETCH_FAILED = 'WORKER_NO_SHOW_FETCH_FAILED'
export const WORKER_NO_SHOWS_INVALIDATE = 'WORKER_NO_SHOWS_INVALIDATE'

export const REPEAT_STAFF_FETCH_BEGIN = 'REPEAT_STAFF_FETCH_BEGIN'
export const REPEAT_STAFF_FETCH_SUCCEEDED = 'REPEAT_STAFF_FETCH_SUCCEEDED'
export const REPEAT_STAFF_FETCH_FAILED = 'REPEAT_STAFF_FETCH_FAILED'
export const REPEAT_STAFFS_INVALIDATE = 'REPEAT_STAFFS_INVALIDATE'

export const PERMISSIONS_FETCH_BEGIN = 'PERMISSIONS_FETCH_BEGIN'
export const PERMISSIONS_FETCH_SUCCEEDED = 'PERMISSIONS_FETCH_SUCCEEDED'
export const PERMISSIONS_FETCH_FAILED = 'PERMISSIONS_FETCH_FAILED'

export const LISTING_PUBLICATION_UPDATE_BEGIN = 'LISTING_PUBLICATION_UPDATE_BEGIN'
export const LISTING_PUBLICATION_UPDATE_SUCCEEDED = 'LISTING_PUBLICATION_UPDATE_SUCCEEDED'
export const LISTING_PUBLICATION_UPDATE_FAILED = 'LISTING_PUBLICATION_UPDATE_FAILED'

export const LISTING_PUBLISH_UPDATE_BEGIN = 'LISTING_PUBLISH_UPDATE_BEGIN'
export const LISTING_PUBLISH_UPDATE_SUCCEEDED = 'LISTING_PUBLISH_UPDATE_SUCCEEDED'
export const LISTING_PUBLISH_UPDATE_FAILED = 'LISTING_PUBLISH_UPDATE_FAILED'

export const JOB_FETCH_BEGIN = 'JOB_FETCH_BEGIN'
export const JOB_FETCH_SUCCEEDED = 'JOB_FETCH_SUCCEEDED'
export const JOB_FETCH_FAILED = 'JOB_FETCH_FAILED'
export const JOBS_FETCH_BEGIN = 'JOBS_FETCH_BEGIN'
export const JOBS_FETCH_SUCCEEDED = 'JOBS_FETCH_SUCCEEDED'
export const JOBS_FETCH_FAILED = 'JOBS_FETCH_FAILED'
export const JOB_DELETE_BEGIN = 'JOB_DELETE_BEGIN'
export const JOB_DELETE_SUCCEEDED = 'JOB_DELETE_SUCCEEDED'
export const JOB_DELETE_FAILED = 'JOB_DELETE_FAILED'

export const JOB_DURATION_PREVIEW_CREATE_BEGIN = 'JOB_DURATION_PREVIEW_CREATE_BEGIN'
export const JOB_DURATION_PREVIEW_CREATE_SUCCEEDED = 'JOB_DURATION_PREVIEW_CREATE_SUCCEEDED'
export const JOB_DURATION_PREVIEW_CREATE_FAILED = 'JOB_DURATION_PREVIEW_CREATE_FAILED'

export const EMPLOYER_TIMESHEET_ENTRY_UPDATE_BEGIN = 'EMPLOYER_TIMESHEET_ENTRY_UPDATE_BEGIN'
export const EMPLOYER_TIMESHEET_ENTRY_UPDATE_FAILED = 'EMPLOYER_TIMESHEET_ENTRY_UPDATE_FAILED'
export const EMPLOYER_TIMESHEET_ENTRY_UPDATE_SUCCEEDED = 'EMPLOYER_TIMESHEET_ENTRY_UPDATE_SUCCEEDED'

export const EMPLOYER_TIMESHEET_RATING_FETCH_BEGIN = 'EMPLOYER_TIMESHEET_RATING_FETCH_BEGIN'
export const EMPLOYER_TIMESHEET_RATING_FETCH_FAILED = 'EMPLOYER_TIMESHEET_RATING_FETCH_FAILED'
export const EMPLOYER_TIMESHEET_RATING_FETCH_SUCCEEDED = 'EMPLOYER_TIMESHEET_RATING_FETCH_SUCCEEDED'

export const EMPLOYER_TIMESHEET_RATING_CREATE_BEGIN = 'EMPLOYER_TIMESHEET_RATING_CREATE_BEGIN'
export const EMPLOYER_TIMESHEET_RATING_CREATE_FAILED = 'EMPLOYER_TIMESHEET_RATING_CREATE_FAILED'
export const EMPLOYER_TIMESHEET_RATING_CREATE_SUCCEEDED = 'EMPLOYER_TIMESHEET_RATING_CREATE_SUCCEEDED'

export const EMPLOYER_TIMESHEET_RATINGS_INVALIDATE = 'EMPLOYER_TIMESHEET_RATINGS_INVALIDATE'

export const EMPLOYER_TIMESHEETS_FETCH_BEGIN = 'EMPLOYER_TIMESHEETS_FETCH_BEGIN'
export const EMPLOYER_TIMESHEETS_FETCH_SUCCEEDED = 'EMPLOYER_TIMESHEETS_FETCH_SUCCEEDED'
export const EMPLOYER_TIMESHEETS_FETCH_FAILED = 'EMPLOYER_TIMESHEETS_FETCH_FAILED'

export const EMPLOYER_TIMESHEET_CREATE_BEGIN = 'EMPLOYER_TIMESHEET_CREATE_BEGIN'
export const EMPLOYER_TIMESHEET_CREATE_SUCCEEDED = 'EMPLOYER_TIMESHEET_CREATE_SUCCEEDED'
export const EMPLOYER_TIMESHEET_CREATE_FAILED = 'EMPLOYER_TIMESHEET_CREATE_FAILED'

export const EMPLOYER_TIMESHEET_UPDATE_BEGIN = 'EMPLOYER_TIMESHEET_UPDATE_BEGIN'
export const EMPLOYER_TIMESHEET_UPDATE_SUCCEEDED = 'EMPLOYER_TIMESHEET_UPDATE_SUCCEEDED'
export const EMPLOYER_TIMESHEET_UPDATE_FAILED = 'EMPLOYER_TIMESHEET_UPDATE_FAILED'

export const EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_BEGIN = 'EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_BEGIN'
export const EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_SUCCEEDED =
  'EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_SUCCEEDED'
export const EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_FAILED = 'EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_FAILED'

export const EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN = 'EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN'
export const EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_SUCCEEDED =
  'EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_SUCCEEDED'
export const EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_FAILED = 'EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_FAILED'

export const EMPLOYER_TIMESHEETS_APPROVAL_CREATE_BEGIN = 'EMPLOYER_TIMESHEETS_APPROVAL_CREATE_BEGIN'
export const EMPLOYER_TIMESHEETS_APPROVAL_CREATE_FAILED = 'EMPLOYER_TIMESHEETS_APPROVAL_CREATE_FAILED'
export const EMPLOYER_TIMESHEETS_APPROVAL_CREATE_SUCCEEDED = 'EMPLOYER_TIMESHEETS_APPROVAL_CREATE_SUCCEEDED'

export const EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_BEGIN = 'EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_BEGIN'
export const EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_FAILED = 'EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_FAILED'
export const EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_SUCCEEDED =
  'EMPLOYER_TIMESHEETS_APPROVE_ALL_FETCH_SUCCEEDED'

export const EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_BEGIN =
  'EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_BEGIN'
export const EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_FAILED =
  'EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_FAILED'
export const EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_SUCCEEDED =
  'EMPLOYER_TIMESHEET_PROVISIONAL_COST_FETCH_SUCCEEDED'

export const AGENCY_ACCOUNTS_FETCH_BEGIN = 'AGENCY_ACCOUNTS_FETCH_BEGIN'
export const AGENCY_ACCOUNTS_FETCH_SUCCEEDED = 'AGENCY_ACCOUNTS_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNTS_FETCH_FAILED = 'AGENCY_ACCOUNTS_FETCH_FAILED'

export const AGENCY_ACCOUNT_VENUES_FETCH_BEGIN = 'AGENCY_ACCOUNT_VENUES_FETCH_BEGIN'
export const AGENCY_ACCOUNT_VENUES_FETCH_SUCCEEDED = 'AGENCY_ACCOUNT_VENUES_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNT_VENUES_FETCH_FAILED = 'AGENCY_ACCOUNT_VENUES_FETCH_FAILED'

export const AGENCY_BRANCH_FETCH_BEGIN = 'AGENCY_BRANCH_FETCH_BEGIN'
export const AGENCY_BRANCH_FETCH_SUCCEEDED = 'AGENCY_BRANCH_FETCH_SUCCEEDED'
export const AGENCY_BRANCH_FETCH_FAILED = 'AGENCY_BRANCH_FETCH_FAILED'

export const AGENCY_ACCOUNT_PERMISSIONS_FETCH_BEGIN = 'AGENCY_ACCOUNT_PERMISSIONS_FETCH_BEGIN'
export const AGENCY_ACCOUNT_PERMISSIONS_FETCH_SUCCEEDED = 'AGENCY_ACCOUNT_PERMISSIONS_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNT_PERMISSIONS_FETCH_FAILED = 'AGENCY_ACCOUNT_PERMISSIONS_FETCH_FAILED'

export const AGENCY_ACCOUNT_DETAIL_CREATE_BEGIN = 'AGENCY_ACCOUNT_DETAIL_CREATE_BEGIN'
export const AGENCY_ACCOUNT_DETAIL_CREATE_SUCCEEDED = 'AGENCY_ACCOUNT_DETAIL_CREATE_SUCCEEDED'
export const AGENCY_ACCOUNT_DETAIL_CREATE_FAILED = 'AGENCY_ACCOUNT_DETAIL_CREATE_FAILED'

export const AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_BEGIN = 'AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_BEGIN'
export const AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_SUCCEEDED =
  'AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_SUCCEEDED'
export const AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_FAILED = 'AGENCY_ACCOUNT_RESEND_INVITATION_CREATE_FAILED'

export const AGENCY_ACCOUNT_BRANCH_CREATE_BEGIN = 'AGENCY_ACCOUNT_BRANCH_CREATE_BEGIN'
export const AGENCY_ACCOUNT_BRANCH_CREATE_FAILED = 'AGENCY_ACCOUNT_BRANCHES_CREATE_FAILED'
export const AGENCY_ACCOUNT_BRANCH_UPDATE_BEGIN = 'AGENCY_ACCOUNT_BRANCH_UPDATE_BEGIN'
export const AGENCY_ACCOUNT_BRANCH_UPDATE_SUCCEEDED = 'AGENCY_ACCOUNT_BRANCH_UPDATE_SUCCEEDED'
export const AGENCY_ACCOUNT_BRANCH_UPDATE_FAILED = 'AGENCY_ACCOUNT_BRANCH_UPDATE_FAILED'

export const AGENCY_ACCOUNT_BRANCHES_FETCH_BEGIN = 'AGENCY_ACCOUNT_BRANCHES_FETCH_BEGIN'
export const AGENCY_ACCOUNT_BRANCHES_FETCH_SUCCEEDED = 'AGENCY_ACCOUNT_BRANCHES_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNT_BRANCHES_FETCH_FAILED = 'AGENCY_ACCOUNT_BRANCHES_FETCH_FAILED'

export const AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN = 'AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN'
export const AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_SUCCEEDED = 'AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_SUCCEEDED'
export const AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_FAILED = 'AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_FAILED'

export const AGENCY_TIMESHEET_WORKERS_FETCH_BEGIN = 'AGENCY_TIMESHEET_WORKERS_FETCH_BEGIN'
export const AGENCY_TIMESHEET_WORKERS_FETCH_SUCCEEDED = 'AGENCY_TIMESHEET_WORKERS_FETCH_SUCCEEDED'
export const AGENCY_TIMESHEET_WORKERS_FETCH_FAILED = 'AGENCY_TIMESHEET_WORKERS_FETCH_FAILED'

export const AGENCY_WORKERS_FETCH_BEGIN = 'AGENCY_WORKERS_FETCH_BEGIN'
export const AGENCY_WORKERS_FETCH_SUCCEEDED = 'AGENCY_WORKERS_FETCH_SUCCEEDED'
export const AGENCY_WORKERS_FETCH_FAILED = 'AGENCY_WORKERS_FETCH_FAILED'
export const AGENCY_WORKER_CREATE_BEGIN = 'AGENCY_WORKER_CREATE_BEGIN'
export const AGENCY_WORKER_CREATE_FAILED = 'AGENCY_WORKER_CREATE_FAILED'
export const AGENCY_WORKER_UPDATE_BEGIN = 'AGENCY_WORKER_UPDATE_BEGIN'
export const AGENCY_WORKER_UPDATE_SUCCEEDED = 'AGENCY_WORKER_UPDATE_SUCCEEDED'
export const AGENCY_WORKER_UPDATE_FAILED = 'AGENCY_WORKER_UPDATE_FAILED'
export const AGENCY_WORKERS_INVALIDATE = 'AGENCY_WORKERS_INVALIDATE'

export const TIMESHEET_FETCH_BEGIN = 'TIMESHEET_FETCH_BEGIN'
export const TIMESHEET_FETCH_SUCCEEDED = 'TIMESHEET_FETCH_SUCCEEDED'
export const TIMESHEET_FETCH_FAILED = 'TIMESHEET_FETCH_FAILED'
export const TIMESHEET_UPDATE_BEGIN = 'TIMESHEET_UPDATE_BEGIN'
export const TIMESHEET_UPDATE_SUCCEEDED = 'TIMESHEET_UPDATE_SUCCEEDED'
export const TIMESHEET_UPDATE_FAILED = 'TIMESHEET_UPDATE_FAILED'

export const TIMESHEET_ENTRY_UPDATE_BEGIN = 'TIMESHEET_ENTRY_UPDATE_BEGIN'
export const TIMESHEET_ENTRY_UPDATE_SUCCEEDED = 'TIMESHEET_ENTRY_UPDATE_SUCCEEDED'
export const TIMESHEET_ENTRY_UPDATE_FAILED = 'TIMESHEET_ENTRY_UPDATE_FAILED'
export const TIMESHEET_ENTRIES_FETCH_BEGIN = 'TIMESHEET_ENTRIES_FETCH_BEGIN'
export const TIMESHEET_ENTRIES_FETCH_SUCCEEDED = 'TIMESHEET_ENTRIES_FETCH_SUCCEEDED'
export const TIMESHEET_ENTRIES_FETCH_FAILED = 'TIMESHEET_ENTRIES_FETCH_FAILED'
export const TIMESHEET_ENTRY_FETCH_BEGIN = 'TIMESHEET_ENTRY_FETCH_BEGIN'
export const TIMESHEET_ENTRY_FETCH_SUCCEEDED = 'TIMESHEET_ENTRY_FETCH_SUCCEEDED'
export const TIMESHEET_ENTRY_FETCH_FAILED = 'TIMESHEET_ENTRY_FETCH_FAILED'

export const EMPLOYER_TIMESHEET_FETCH_BEGIN = 'EMPLOYER_TIMESHEET_FETCH_BEGIN'
export const EMPLOYER_TIMESHEET_FETCH_SUCCEEDED = 'EMPLOYER_TIMESHEET_FETCH_SUCCEEDED'
export const EMPLOYER_TIMESHEET_FETCH_FAILED = 'EMPLOYER_TIMESHEET_FETCH_FAILED'

export const TIMESHEET_APPROVAL_STAT_FETCH_BEGIN = 'TIMESHEET_APPROVAL_STAT_FETCH_BEGIN'
export const TIMESHEET_APPROVAL_STAT_FETCH_SUCCEEDED = 'TIMESHEET_APPROVAL_STAT_FETCH_SUCCEEDED'
export const TIMESHEET_APPROVAL_STAT_FETCH_FAILED = 'TIMESHEET_APPROVAL_STAT_FETCH_FAILED'

export const LISTING_APPROVAL_DETAIL_FETCH_BEGIN = 'LISTING_APPROVAL_DETAIL_FETCH_BEGIN'
export const LISTING_APPROVAL_DETAIL_FETCH_SUCCEEDED = 'LISTING_APPROVAL_DETAIL_FETCH_SUCCEEDED'
export const LISTING_APPROVAL_DETAIL_FETCH_FAILED = 'LISTING_APPROVAL_DETAIL_FETCH_FAILED'

export const LISTING_ROLES_FETCH_BEGIN = 'LISTING_ROLES_FETCH_BEGIN'
export const LISTING_ROLES_FETCH_SUCCEEDED = 'LISTING_ROLES_FETCH_SUCCEEDED'
export const LISTING_ROLES_FETCH_FAILED = 'LISTING_ROLES_FETCH_FAILED'

export const TIMESHEET_EMAIL_CREATE_BEGIN = 'TIMESHEET_EMAIL_CREATE_BEGIN'
export const TIMESHEET_EMAIL_CREATE_SUCCEEDED = 'TIMESHEET_EMAIL_CREATE_SUCCEEDED'
export const TIMESHEET_EMAIL_CREATE_FAILED = 'TIMESHEET_EMAIL_CREATE_FAILED'

export const TIMESHEET_PROVISIONAL_COST_FETCH_BEGIN = 'TIMESHEET_PROVISIONAL_COST_FETCH_BEGIN'
export const TIMESHEET_PROVISIONAL_COST_FETCH_SUCCEEDED = 'TIMESHEET_PROVISIONAL_COST_FETCH_SUCCEEDED'
export const TIMESHEET_PROVISIONAL_COST_FETCH_FAILED = 'TIMESHEET_PROVISIONAL_COST_FETCH_FAILED'

export const TIMESHEET_AGENCY_ENTRY_UPDATE_BEGIN = 'TIMESHEET_AGENCY_ENTRY_UPDATE_BEGIN'
export const TIMESHEET_AGENCY_ENTRY_UPDATE_SUCCEEDED = 'TIMESHEET_AGENCY_ENTRY_UPDATE_SUCCEEDED'
export const TIMESHEET_AGENCY_ENTRY_UPDATE_FAILED = 'TIMESHEET_AGENCY_ENTRY_UPDATE_FAILED'
export const TIMESHEET_AGENCY_ENTRIES_FETCH_BEGIN = 'TIMESHEET_AGENCY_ENTRIES_FETCH_BEGIN'
export const TIMESHEET_AGENCY_ENTRIES_FETCH_SUCCEEDED = 'TIMESHEET_AGENCY_ENTRIES_FETCH_SUCCEEDED'
export const TIMESHEET_AGENCY_ENTRIES_FETCH_FAILED = 'TIMESHEET_AGENCY_ENTRIES_FETCH_FAILED'
export const TIMESHEET_AGENCY_ENTRY_FETCH_BEGIN = 'TIMESHEET_AGENCY_ENTRY_FETCH_BEGIN'
export const TIMESHEET_AGENCY_ENTRY_FETCH_SUCCEEDED = 'TIMESHEET_AGENCY_ENTRY_FETCH_SUCCEEDED'
export const TIMESHEET_AGENCY_ENTRY_FETCH_FAILED = 'TIMESHEET_AGENCY_ENTRY_FETCH_FAILED'

export const EMPLOYER_BULK_BREAK_CREATE_BEGIN = 'EMPLOYER_BULK_BREAK_CREATE_BEGIN'
export const EMPLOYER_BULK_BREAK_CREATE_SUCCEEDED = 'EMPLOYER_BULK_BREAK_CREATE_SUCCEEDED'
export const EMPLOYER_BULK_BREAK_CREATE_FAILED = 'EMPLOYER_BULK_BREAK_CREATE_FAILED'

export const RATINGS_FETCH_BEGIN = 'RATINGS_FETCH_BEGIN'
export const RATINGS_FETCH_SUCCEEDED = 'RATINGS_FETCH_SUCCEEDED'
export const RATINGS_FETCH_FAILED = 'RATINGS_FETCH_FAILED'
export const RATING_CREATE_BEGIN = 'RATING_CREATE_BEGIN'
export const RATING_CREATE_SUCCEEDED = 'RATING_CREATE_SUCCEEDED'
export const RATING_CREATE_FAILED = 'RATING_CREATE_FAILED'
export const RATING_UPDATE_BEGIN = 'RATING_UPDATE_BEGIN'
export const RATING_UPDATE_SUCCEEDED = 'RATING_UPDATE_SUCCEEDED'
export const RATING_UPDATE_FAILED = 'RATING_UPDATE_FAILED'
export const RATINGS_UPDATE_BEGIN = 'RATINGS_UPDATE_BEGIN'
export const RATINGS_UPDATE_SUCCEEDED = 'RATINGS_UPDATE_SUCCEEDED'
export const RATINGS_UPDATE_FAILED = 'RATINGS_UPDATE_FAILED'

export const VENUES_FETCH_BEGIN = 'VENUES_FETCH_BEGIN'
export const VENUES_FETCH_SUCCEEDED = 'VENUES_FETCH_SUCCEEDED'
export const VENUES_FETCH_FAILED = 'VENUES_FETCH_FAILED'
export const VENUE_FETCH_BEGIN = 'VENUE_FETCH_BEGIN'
export const VENUE_FETCH_SUCCEEDED = 'VENUE_FETCH_SUCCEEDED'
export const VENUE_FETCH_FAILED = 'VENUE_FETCH_FAILED'
export const VENUE_CREATE_BEGIN = 'VENUE_CREATE_BEGIN'
export const VENUE_CREATE_SUCCEEDED = 'VENUE_CREATE_SUCCEEDED'
export const VENUE_CREATE_FAILED = 'VENUE_CREATE_FAILED'
export const VENUE_UPDATE_BEGIN = 'VENUE_UPDATE_BEGIN'
export const VENUE_UPDATE_SUCCEEDED = 'VENUE_UPDATE_SUCCEEDED'
export const VENUE_UPDATE_FAILED = 'VENUE_UPDATE_FAILED'
export const VENUE_DELETE_BEGIN = 'VENUE_DELETE_BEGIN'
export const VENUE_DELETE_SUCCEEDED = 'VENUE_DELETE_SUCCEEDED'
export const VENUE_DELETE_FAILED = 'VENUE_DELETE_FAILED'

export const VENUES_WITH_AREAS_FETCH_BEGIN = 'VENUES_WITH_AREAS_FETCH_BEGIN'
export const VENUES_WITH_AREAS_FETCH_SUCCEEDED = 'VENUES_WITH_AREAS_FETCH_SUCCEEDED'
export const VENUES_WITH_AREAS_FETCH_FAILED = 'VENUES_WITH_AREAS_FETCH_FAILED'

export const REMOVE_WORKER_FROM_ALLOCATION_CREATE_BEGIN = 'REMOVE_WORKER_FROM_ALLOCATION_CREATE_BEGIN'
export const REMOVE_WORKER_FROM_ALLOCATION_CREATE_SUCCEEDED = 'REMOVE_WORKER_FROM_ALLOCATION_CREATE_SUCCEEDED'
export const REMOVE_WORKER_FROM_ALLOCATION_CREATE_FAILED = 'REMOVE_WORKER_FROM_ALLOCATION_CREATE_FAILED'

export const WORK_LOCATION_FETCH_BEGIN = 'WORK_LOCATION_FETCH_BEGIN'
export const WORK_LOCATION_FETCH_SUCCEEDED = 'WORK_LOCATION_FETCH_SUCCEEDED'
export const WORK_LOCATION_FETCH_FAILED = 'WORK_LOCATION_FETCH_FAILED'

export const LIGHT_VENUES_FETCH_BEGIN = 'LIGHT_VENUES_FETCH_BEGIN'
export const LIGHT_VENUES_FETCH_SUCCEEDED = 'LIGHT_VENUES_FETCH_SUCCEEDED'
export const LIGHT_VENUES_FETCH_FAILED = 'LIGHT_VENUES_FETCH_FAILED'

export const AREA_CREATE_BEGIN = 'AREA_CREATE_BEGIN'
export const AREA_CREATE_SUCCEEDED = 'AREA_CREATE_SUCCEEDED'
export const AREA_CREATE_FAILED = 'AREA_CREATE_FAILED'
export const AREA_UPDATE_BEGIN = 'AREA_UPDATE_BEGIN'
export const AREA_UPDATE_SUCCEEDED = 'AREA_UPDATE_SUCCEEDED'
export const AREA_UPDATE_FAILED = 'AREA_UPDATE_FAILED'

export const INDUSTRIES_FETCH_BEGIN = 'INDUSTRIES_FETCH_BEGIN'
export const INDUSTRIES_FETCH_SUCCEEDED = 'INDUSTRIES_FETCH_SUCCEEDED'
export const INDUSTRIES_FETCH_FAILED = 'INDUSTRIES_FETCH_FAILED'

export const COUNTRIES_FETCH_BEGIN = 'COUNTRIES_FETCH_BEGIN'
export const COUNTRIES_FETCH_SUCCEEDED = 'COUNTRIES_FETCH_SUCCEEDED'
export const COUNTRIES_FETCH_FAILED = 'COUNTRIES_FETCH_FAILED'

export const COUNTRY_FETCH_BEGIN = 'COUNTRY_FETCH_BEGIN'
export const COUNTRY_FETCH_SUCCEEDED = 'COUNTRY_FETCH_SUCCEEDED'
export const COUNTRY_FETCH_FAILED = 'COUNTRY_FETCH_FAILED'

export const ROLES_FETCH_BEGIN = 'ROLES_FETCH_BEGIN'
export const ROLES_FETCH_SUCCEEDED = 'ROLES_FETCH_SUCCEEDED'
export const ROLES_FETCH_FAILED = 'ROLES_FETCH_FAILED'

export const ROLE_FETCH_BEGIN = 'ROLE_FETCH_BEGIN'
export const ROLE_FETCH_SUCCEEDED = 'ROLE_FETCH_SUCCEEDED'
export const ROLE_FETCH_FAILED = 'ROLE_FETCH_FAILED'

export const JOB_RATE_FETCH_BEGIN = 'JOB_RATE_FETCH_BEGIN'
export const JOB_RATE_FETCH_SUCCEEDED = 'JOB_RATE_FETCH_SUCCEEDED'
export const JOB_RATE_FETCH_FAILED = 'JOB_RATE_FETCH_FAILED'

export const STANDARD_UNIFORMS_FETCH_BEGIN = 'STANDARD_UNIFORMS_FETCH_BEGIN'
export const STANDARD_UNIFORMS_FETCH_SUCCEEDED = 'STANDARD_UNIFORMS_FETCH_SUCCEEDED'
export const STANDARD_UNIFORMS_FETCH_FAILED = 'STANDARD_UNIFORMS_FETCH_FAILED'

export const UNIFORM_CREATE_BEGIN = 'UNIFORM_CREATE_BEGIN'
export const UNIFORM_CREATE_SUCCEEDED = 'UNIFORM_CREATE_SUCCEEDED'
export const UNIFORM_CREATE_FAILED = 'UNIFORM_CREATE_FAILED'
export const UNIFORM_UPDATE_BEGIN = 'UNIFORM_UPDATE_BEGIN'
export const UNIFORM_UPDATE_SUCCEEDED = 'UNIFORM_UPDATE_SUCCEEDED'
export const UNIFORM_UPDATE_FAILED = 'UNIFORM_UPDATE_FAILED'

export const SKILLS_FETCH_BEGIN = 'SKILLS_FETCH_BEGIN'
export const SKILLS_FETCH_SUCCEEDED = 'SKILLS_FETCH_SUCCEEDED'
export const SKILLS_FETCH_FAILED = 'SKILLS_FETCH_FAILED'

export const WAGE_PREVIEW_FETCH_BEGIN = 'WAGE_PREVIEW_FETCH_BEGIN'
export const WAGE_PREVIEW_FETCH_SUCCEEDED = 'WAGE_PREVIEW_FETCH_SUCCEEDED'
export const WAGE_PREVIEW_FETCH_FAILED = 'WAGE_PREVIEW_FETCH_FAILED'
export const WAGE_PREVIEW_CREATE_BEGIN = 'WAGE_PREVIEW_CREATE_BEGIN'
export const WAGE_PREVIEW_CREATE_SUCCEEDED = 'WAGE_PREVIEW_CREATE_SUCCEEDED'
export const WAGE_PREVIEW_CREATE_FAILED = 'WAGE_PREVIEW_CREATE_FAILED'
export const WAGE_PREVIEW_INVALIDATE = 'WAGE_PREVIEW_INVALIDATE'

export const JOB_CANDIDATES_FETCH_BEGIN = 'JOB_CANDIDATES_FETCH_BEGIN'
export const JOB_CANDIDATES_FETCH_SUCCEEDED = 'JOB_CANDIDATES_FETCH_SUCCEEDED'
export const JOB_CANDIDATES_FETCH_FAILED = 'JOB_CANDIDATES_FETCH_FAILED'
export const JOB_CANDIDATES_INVALIDATE = 'JOB_CANDIDATES_INVALIDATE'

export const JOB_CANDIDATE_CREATE_SUCCEEDED = 'JOB_CANDIDATE_CREATE_SUCCEEDED'

export const JOB_CANDIDATE_UPDATE_BEGIN = 'JOB_CANDIDATE_UPDATE_BEGIN'
export const JOB_CANDIDATE_UPDATE_SUCCEEDED = 'JOB_CANDIDATE_UPDATE_SUCCEEDED'
export const JOB_CANDIDATE_UPDATE_FAILED = 'JOB_CANDIDATE_UPDATE_FAILED'

export const JOB_CANDIDATE_DELETE_BEGIN = 'JOB_CANDIDATE_DELETE_BEGIN'
export const JOB_CANDIDATE_DELETE_SUCCEEDED = 'JOB_CANDIDATE_DELETE_SUCCEEDED'
export const JOB_CANDIDATE_DELETE_FAILED = 'JOB_CANDIDATE_DELETE_FAILED'

export const JOB_IDS_FETCH_BEGIN = 'JOB_IDS_FETCH_BEGIN'
export const JOB_IDS_FETCH_SUCCEEDED = 'JOB_IDS_FETCH_SUCCEEDED'
export const JOB_IDS_FETCH_FAILED = 'JOB_IDS_FETCH_FAILED'

export const AGENCIES_FETCH_BEGIN = 'AGENCIES_FETCH_BEGIN'
export const AGENCIES_FETCH_SUCCEEDED = 'AGENCIES_FETCH_SUCCEEDED'
export const AGENCIES_FETCH_FAILED = 'AGENCIES_FETCH_FAILED'

export const LISTING_AGENCY_SHIFTS_CREATE_BEGIN = 'LISTING_AGENCY_SHIFTS_CREATE_BEGIN'
export const LISTING_AGENCY_SHIFTS_CREATE_SUCCEEDED = 'LISTING_AGENCY_SHIFTS_CREATE_SUCCEEDED'
export const LISTING_AGENCY_SHIFTS_CREATE_FAILED = 'LISTING_AGENCY_SHIFTS_CREATE_FAILED'

export const LISTING_AGENCY_SHIFTS_DELETE_BEGIN = 'LISTING_AGENCY_SHIFTS_DELETE_BEGIN'
export const LISTING_AGENCY_SHIFTS_DELETE_SUCCEEDED = 'LISTING_AGENCY_SHIFTS_DELETE_SUCCEEDED'
export const LISTING_AGENCY_SHIFTS_DELETE_FAILED = 'LISTING_AGENCY_SHIFTS_DELETE_FAILED'

export const LISTING_PRESET_REASONS_FETCH_BEGIN = 'LISTING_PRESET_REASONS_FETCH_BEGIN'
export const LISTING_PRESET_REASONS_FETCH_SUCCEEDED = 'LISTING_PRESET_REASONS_FETCH_SUCCEEDED'
export const LISTING_PRESET_REASONS_FETCH_FAILED = 'LISTING_PRESET_REASONS_FETCH_FAILED'

export const AGENCY_SHIFTS_FETCH_BEGIN = 'AGENCY_SHIFTS_FETCH_BEGIN'
export const AGENCY_SHIFTS_FETCH_SUCCEEDED = 'AGENCY_SHIFTS_FETCH_SUCCEEDED'
export const AGENCY_SHIFTS_FETCH_FAILED = 'AGENCY_SHIFTS_FETCH_FAILED'

export const AGENCY_SHIFT_CREATE_BEGIN = 'AGENCY_SHIFT_CREATE_BEGIN'
export const AGENCY_SHIFT_CREATE_SUCCEEDED = 'AGENCY_SHIFT_CREATE_SUCCEEDED'
export const AGENCY_SHIFT_CREATE_FAILED = 'AGENCY_SHIFT_CREATE_FAILED'
export const AGENCY_SHIFTS_INVALIDATE = 'AGENCY_SHIFTS_INVALIDATE'

export const AGENCY_SHIFT_UPDATE_BEGIN = 'AGENCY_SHIFT_UPDATE_BEGIN'
export const AGENCY_SHIFT_UPDATE_SUCCEEDED = 'AGENCY_SHIFT_UPDATE_SUCCEEDED'
export const AGENCY_SHIFT_UPDATE_FAILED = 'AGENCY_SHIFT_UPDATE_FAILED'

export const AGENCY_SHIFT_DELETE_BEGIN = 'AGENCY_SHIFT_DELETE_BEGIN'
export const AGENCY_SHIFT_DELETE_SUCCEEDED = 'AGENCY_SHIFT_DELETE_SUCCEEDED'
export const AGENCY_SHIFT_DELETE_FAILED = 'AGENCY_SHIFT_DELETE_FAILED'

export const AGENCY_SHIFT_WORKERS_FETCH_BEGIN = 'AGENCY_SHIFT_WORKERS_FETCH_BEGIN'
export const AGENCY_SHIFT_WORKERS_FETCH_SUCCEEDED = 'AGENCY_SHIFT_WORKERS_FETCH_SUCCEEDED'
export const AGENCY_SHIFT_WORKERS_FETCH_FAILED = 'AGENCY_SHIFT_WORKERS_FETCH_FAILED'

export const AGENCY_SHIFT_WORKER_FETCH_BEGIN = 'AGENCY_SHIFT_WORKER_FETCH_BEGIN'
export const AGENCY_SHIFT_WORKER_FETCH_SUCCEEDED = 'AGENCY_SHIFT_WORKER_FETCH_SUCCEEDED'
export const AGENCY_SHIFT_WORKER_FETCH_FAILED = 'AGENCY_SHIFT_WORKER_FETCH_FAILED'

export const AGENCY_SHIFT_WORKER_UPDATE_BEGIN = 'AGENCY_SHIFT_WORKER_UPDATE_BEGIN'
export const AGENCY_SHIFT_WORKER_UPDATE_SUCCEEDED = 'AGENCY_SHIFT_WORKER_UPDATE_SUCCEEDED'
export const AGENCY_SHIFT_WORKER_UPDATE_FAILED = 'AGENCY_SHIFT_WORKER_UPDATE_FAILED'

export const AGENCY_SHIFT_WORKER_DELETE_BEGIN = 'AGENCY_SHIFT_WORKER_DELETE_BEGIN'
export const AGENCY_SHIFT_WORKER_DELETE_SUCCEEDED = 'AGENCY_SHIFT_WORKER_DELETE_SUCCEEDED'
export const AGENCY_SHIFT_WORKER_DELETE_FAILED = 'AGENCY_SHIFT_WORKER_DELETE_FAILED'

export const MULTIPLE_BOOKING_CREATE_BEGIN = 'MULTIPLE_BOOKING_CREATE_BEGIN'
export const MULTIPLE_BOOKING_CREATE_SUCCEEDED = 'MULTIPLE_BOOKING_CREATE_SUCCEEDED'
export const MULTIPLE_BOOKING_CREATE_FAILED = 'MULTIPLE_BOOKING_CREATE_FAILED'

export const CANCEL_BOOKING_CREATE_BEGIN = 'CANCEL_BOOKING_CREATE_BEGIN'
export const CANCEL_BOOKING_CREATE_SUCCEEDED = 'CANCEL_BOOKING_CREATE_SUCCEEDED'
export const CANCEL_BOOKING_CREATE_FAILED = 'CANCEL_BOOKING_CREATE_FAILED'

export const BULK_OFFER_CREATE_BEGIN = 'BULK_OFFER_CREATE_BEGIN'
export const BULK_OFFER_CREATE_SUCCEEDED = 'BULK_OFFER_CREATE_SUCCEEDED'
export const BULK_OFFER_CREATE_FAILED = 'BULK_OFFER_CREATE_FAILED'

export const BULK_DELETE_CREATE_BEGIN = 'BULK_DELETE_CREATE_BEGIN'
export const BULK_DELETE_CREATE_SUCCEEDED = 'BULK_DELETE_CREATE_SUCCEEDED'
export const BULK_DELETE_CREATE_FAILED = 'BULK_DELETE_CREATE_FAILED'

export const BULK_MESSAGE_CREATE_BEGIN = 'BULK_MESSAGE_CREATE_BEGIN'
export const BULK_MESSAGE_CREATE_SUCCEEDED = 'BULK_MESSAGE_CREATE_SUCCEEDED'
export const BULK_MESSAGE_CREATE_FAILED = 'BULK_MESSAGE_CREATE_FAILED'

export const REFERRAL_CLAIM_CREATE_BEGIN = 'REFERRAL_CLAIM_CREATE_BEGIN'
export const REFERRAL_CLAIM_CREATE_SUCCEEDED = 'REFERRAL_CLAIM_CREATE_SUCCEEDED'
export const REFERRAL_CLAIM_CREATE_FAILED = 'REFERRAL_CLAIM_CREATE_FAILED'

export const WORKER_FETCH_BEGIN = 'WORKER_FETCH_BEGIN'
export const WORKER_FETCH_SUCCEEDED = 'WORKER_FETCH_SUCCEEDED'
export const WORKER_FETCH_FAILED = 'WORKER_FETCH_FAILED'
export const WORKER_UPDATE_BEGIN = 'WORKER_UPDATE_BEGIN'
export const WORKER_UPDATE_SUCCEEDED = 'WORKER_UPDATE_SUCCEEDED'
export const WORKER_UPDATE_FAILED = 'WORKER_UPDATE_FAILED'
export const WORKER_DELETE_BEGIN = 'WORKER_DELETE_BEGIN'
export const WORKER_DELETE_SUCCEEDED = 'WORKER_DELETE_SUCCEEDED'
export const WORKER_DELETE_FAILED = 'WORKER_DELETE_FAILED'
export const WORKERS_FETCH_BEGIN = 'WORKERS_FETCH_BEGIN'
export const WORKERS_FETCH_SUCCEEDED = 'WORKERS_FETCH_SUCCEEDED'
export const WORKERS_FETCH_FAILED = 'WORKERS_FETCH_FAILED'
export const WORKERS_INVALIDATE = 'WORKERS_INVALIDATE'

export const WORKER_NAMES_FETCH_BEGIN = 'WORKER_NAMES_FETCH_BEGIN'
export const WORKER_NAMES_FETCH_FAILED = 'WORKER_DELETE_FETCH_FAILED'
export const WORKER_NAMES_FETCH_SUCCEEDED = 'WORKER_NAMES_FETCH_SUCCEEDED'

export const WORKER_SIGNUP_INVITATION_FETCH_BEGIN = 'WORKER_SIGNUP_INVITATION_FETCH_BEGIN'
export const WORKER_SIGNUP_INVITATION_FETCH_SUCCEEDED = 'WORKER_SIGNUP_INVITATION_FETCH_SUCCEEDED'
export const WORKER_SIGNUP_INVITATION_FETCH_FAILED = 'WORKER_SIGNUP_INVITATION_FETCH_FAILED'

export const INTERNAL_WORKER_INVITATIONS_FETCH_BEGIN = 'INTERNAL_WORKER_INVITATIONS_FETCH_BEGIN'
export const INTERNAL_WORKER_INVITATIONS_FETCH_SUCCEEDED = 'INTERNAL_WORKER_INVITATIONS_FETCH_SUCCEEDED'
export const INTERNAL_WORKER_INVITATIONS_FETCH_FAILED = 'INTERNAL_WORKER_INVITATIONS_FETCH_FAILED'
export const INTERNAL_WORKER_INVITATION_CREATE_BEGIN = 'INTERNAL_WORKER_INVITATION_CREATE_BEGIN'
export const INTERNAL_WORKER_INVITATION_CREATE_SUCCEEDED = 'INTERNAL_WORKER_INVITATION_CREATE_SUCCEEDED'
export const INTERNAL_WORKER_INVITATION_CREATE_FAILED = 'INTERNAL_WORKER_INVITATION_CREATE_FAILED'
export const INTERNAL_WORKER_INVITATION_DELETE_BEGIN = 'INTERNAL_WORKER_INVITATION_DELETE_BEGIN'
export const INTERNAL_WORKER_INVITATION_DELETE_SUCCEEDED = 'INTERNAL_WORKER_INVITATION_DELETE_SUCCEEDED'
export const INTERNAL_WORKER_INVITATION_DELETE_FAILED = 'INTERNAL_WORKER_INVITATION_DELETE_FAILED'

export const INTERNAL_WORKER_INVITATION_RESEND_BEGIN = 'INTERNAL_WORKER_INVITATION_RESEND_BEGIN'
export const INTERNAL_WORKER_INVITATION_RESEND_SUCCEEDED = 'INTERNAL_WORKER_INVITATION_RESEND_SUCCEEDED'
export const INTERNAL_WORKER_INVITATION_RESEND_FAILED = 'INTERNAL_WORKER_INVITATION_RESEND_FAILED'

export const WORKER_ROLE_UPDATE_BEGIN = 'WORKER_ROLE_UPDATE_BEGIN'
export const WORKER_ROLE_UPDATE_SUCCEEDED = 'WORKER_ROLE_UPDATE_SUCCEEDED'
export const WORKER_ROLE_UPDATE_FAILED = 'WORKER_ROLE_UPDATE_FAILED'
export const WORKER_ROLE_CREATE_BEGIN = 'WORKER_ROLE_CREATE_BEGIN'
export const WORKER_ROLE_CREATE_SUCCEEDED = 'WORKER_ROLE_CREATE_SUCCEEDED'
export const WORKER_ROLE_CREATE_FAILED = 'WORKER_ROLE_CREATE_FAILED'
export const WORKER_ROLE_DELETE_BEGIN = 'WORKER_ROLE_DELETE_BEGIN'
export const WORKER_ROLE_DELETE_SUCCEEDED = 'WORKER_ROLE_DELETE_SUCCEEDED'
export const WORKER_ROLE_DELETE_FAILED = 'WORKER_ROLE_DELETE_FAILED'

export const WORKER_VENUES_FETCH_BEGIN = 'WORKER_VENUES_FETCH_BEGIN'
export const WORKER_VENUES_FETCH_SUCCEEDED = 'WORKER_VENUES_FETCH_SUCCEEDED'
export const WORKER_VENUES_FETCH_FAILED = 'WORKER_VENUES_FETCH_FAILED'
export const WORKER_VENUES_UPDATE_BEGIN = 'WORKER_VENUES_UPDATE_BEGIN'
export const WORKER_VENUES_UPDATE_SUCCEEDED = 'WORKER_VENUES_UPDATE_SUCCEEDED'
export const WORKER_VENUES_UPDATE_FAILED = 'WORKER_VENUES_UPDATE_FAILED'
export const WORKER_VENUE_DELETE_BEGIN = 'WORKER_VENUE_DELETE_BEGIN'
export const WORKER_VENUE_DELETE_SUCCEEDED = 'WORKER_VENUE_DELETE_SUCCEEDED'
export const WORKER_VENUE_DELETE_FAILED = 'WORKER_VENUE_DELETE_FAILED'

export const WORKER_JOBS_FETCH_BEGIN = 'WORKER_JOBS_FETCH_BEGIN'
export const WORKER_JOBS_FETCH_SUCCEEDED = 'WORKER_JOBS_FETCH_SUCCEEDED'
export const WORKER_JOBS_FETCH_FAILED = 'WORKER_JOBS_FETCH_FAILED'

export const WORKER_EXPERIENCES_FETCH_BEGIN = 'WORKER_EXPERIENCES_FETCH_BEGIN'
export const WORKER_EXPERIENCES_FETCH_SUCCEEDED = 'WORKER_EXPERIENCES_FETCH_SUCCEEDED'
export const WORKER_EXPERIENCES_FETCH_FAILED = 'WORKER_EXPERIENCES_FETCH_FAILED'

export const WORKER_TRUSTED_WORK_LOCATIONS_FETCH_BEGIN = 'WORKER_TRUSTED_WORK_LOCATIONS_FETCH_BEGIN'
export const WORKER_TRUSTED_WORK_LOCATIONS_FETCH_SUCCEEDED = 'WORKER_TRUSTED_WORK_LOCATIONS_FETCH_SUCCEEDED'
export const WORKER_TRUSTED_WORK_LOCATIONS_FETCH_FAILED = 'WORKER_TRUSTED_WORK_LOCATIONS_FETCH_FAILED'

export const TRUSTED_NETWORK_WORKERS_FETCH_BEGIN = 'TRUSTED_NETWORK_WORKERS_FETCH_BEGIN'
export const TRUSTED_NETWORK_WORKERS_FETCH_SUCCEEDED = 'TRUSTED_NETWORK_WORKERS_FETCH_SUCCEEDED'
export const TRUSTED_NETWORK_WORKERS_FETCH_FAILED = 'TRUSTED_NETWORK_WORKERS_FETCH_FAILED'

export const TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_BEGIN = 'TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_BEGIN'
export const TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_SUCCEEDED = 'TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_SUCCEEDED'
export const TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_FAILED = 'TRUSTED_NETWORK_WORKERS_LIGHT_FETCH_FAILED'

export const TRUSTED_NETWORK_WORKER_UPDATE_BEGIN = 'TRUSTED_NETWORK_WORKER_UPDATE_BEGIN'
export const TRUSTED_NETWORK_WORKER_UPDATE_SUCCEEDED = 'TRUSTED_NETWORK_WORKER_UPDATE_SUCCEEDED'
export const TRUSTED_NETWORK_WORKER_UPDATE_FAILED = 'TRUSTED_NETWORK_WORKER_CREATE_FAILED'

export const TRUSTED_NETWORK_WORKER_CREATE_BEGIN = 'TRUSTED_NETWORK_WORKER_CREATE_BEGIN'
export const TRUSTED_NETWORK_WORKER_CREATE_SUCCEEDED = 'TRUSTED_NETWORK_WORKER_CREATE_SUCCEEDED'
export const TRUSTED_NETWORK_WORKER_CREATE_FAILED = 'TRUSTED_NETWORK_WORKERS_CREATE_FAILED'

export const TRUSTED_NETWORK_WORKER_DELETE_BEGIN = 'TRUSTED_NETWORK_WORKER_DELETE_BEGIN'
export const TRUSTED_NETWORK_WORKER_DELETE_SUCCEEDED = 'TRUSTED_NETWORK_WORKER_DELETE_SUCCEEDED'
export const TRUSTED_NETWORK_WORKER_DELETE_FAILED = 'TRUSTED_NETWORK_WORKER_DELETE_FAILED'

export const TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_BEGIN = 'TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_BEGIN'
export const TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_SUCCEEDED = 'TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_SUCCEEDED'
export const TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_FAILED = 'TRUSTED_NETWORK_WORKER_SHIFTS_FETCH_FAILED'

export const NO_SHOW_REASONS_FETCH_BEGIN = 'NO_SHOW_REASONS_FETCH_BEGIN'
export const NO_SHOW_REASONS_FETCH_SUCCEEDED = 'NO_SHOW_REASONS_FETCH_SUCCEEDED'
export const NO_SHOW_REASONS_FETCH_FAILED = 'NO_SHOW_REASONS_FETCH_FAILED'

export const NON_SYFT_EXPERIENCE_FETCH_BEGIN = 'NON_SYFT_EXPERIENCE_FETCH_BEGIN'
export const NON_SYFT_EXPERIENCE_FETCH_SUCCEEDED = 'NON_SYFT_EXPERIENCE_FETCH_SUCCEEDED'
export const NON_SYFT_EXPERIENCE_FETCH_FAILED = 'NON_SYFT_EXPERIENCE_FETCH_FAILED'
export const NON_SYFT_EXPERIENCE_UPDATE_BEGIN = 'NON_SYFT_EXPERIENCE_UPDATE_BEGIN'
export const NON_SYFT_EXPERIENCE_UPDATE_SUCCEEDED = 'NON_SYFT_EXPERIENCE_UPDATE_SUCCEEDED'
export const NON_SYFT_EXPERIENCE_UPDATE_FAILED = 'NON_SYFT_EXPERIENCE_UPDATE_FAILED'
export const NON_SYFT_EXPERIENCE_CREATE_BEGIN = 'NON_SYFT_EXPERIENCE_CREATE_BEGIN'
export const NON_SYFT_EXPERIENCE_CREATE_SUCCEEDED = 'NON_SYFT_EXPERIENCE_CREATE_SUCCEEDED'
export const NON_SYFT_EXPERIENCE_CREATE_FAILED = 'NON_SYFT_EXPERIENCE_CREATE_FAILED'

export const USER_CREATE_BEGIN = 'USER_CREATE_BEGIN'
export const USER_CREATE_SUCCEEDED = 'USER_CREATE_SUCCEEDED'
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED'

export const USER_CHANGE_EMAIL_PASSWORD_BEGIN = 'USER_CHANGE_EMAIL_PASSWORD_BEGIN'
export const USER_CHANGE_EMAIL_PASSWORD_SUCCEEDED = 'USER_CHANGE_EMAIL_PASSWORD_SUCCEEDED'
export const USER_CHANGE_EMAIL_PASSWORD_FAILED = 'USER_CHANGE_EMAIL_PASSWORD_FAILED'

export const EMPLOYER_FETCH_BEGIN = 'EMPLOYER_FETCH_BEGIN'
export const EMPLOYER_FETCH_SUCCEEDED = 'EMPLOYER_FETCH_SUCCEEDED'
export const EMPLOYER_FETCH_FAILED = 'EMPLOYER_FETCH_FAILED'
export const EMPLOYER_CREATE_BEGIN = 'EMPLOYER_CREATE_BEGIN'
export const EMPLOYER_CREATE_SUCCEEDED = 'EMPLOYER_CREATE_SUCCEEDED'
export const EMPLOYER_CREATE_FAILED = 'EMPLOYER_CREATE_FAILED'
export const EMPLOYER_UPDATE_BEGIN = 'EMPLOYER_UPDATE_BEGIN'
export const EMPLOYER_UPDATE_SUCCEEDED = 'EMPLOYER_UPDATE_SUCCEEDED'
export const EMPLOYER_UPDATE_FAILED = 'EMPLOYER_UPDATE_FAILED'

export const AGENCY_FETCH_BEGIN = 'AGENCY_FETCH_BEGIN'
export const AGENCY_FETCH_SUCCEEDED = 'AGENCY_FETCH_SUCCEEDED'
export const AGENCY_FETCH_FAILED = 'AGENCY_FETCH_FAILED'
export const AGENCY_CREATE_BEGIN = 'AGENCY_CREATE_BEGIN'
export const AGENCY_CREATE_SUCCEEDED = 'AGENCY_CREATE_SUCCEEDED'
export const AGENCY_CREATE_FAILED = 'AGENCY_CREATE_FAILED'
export const AGENCY_UPDATE_BEGIN = 'AGENCY_UPDATE_BEGIN'
export const AGENCY_UPDATE_SUCCEEDED = 'AGENCY_UPDATE_SUCCEEDED'
export const AGENCY_UPDATE_FAILED = 'AGENCY_UPDATE_FAILED'
export const AGENCY_DELETE_BEGIN = 'AGENCY_DELETE_BEGIN'
export const AGENCY_DELETE_SUCCEEDED = 'AGENCY_DELETE_SUCCEEDED'
export const AGENCY_DELETE_FAILED = 'AGENCY_DELETE_FAILED'

export const THREADS_FETCH_BEGIN = 'THREADS_FETCH_BEGIN'
export const THREADS_FETCH_SUCCEEDED = 'THREADS_FETCH_SUCCEEDED'
export const THREADS_FETCH_FAILED = 'THREADS_FETCH_FAILED'
export const THREADS_SYNC_START = 'THREADS_SYNC_START'
export const THREADS_SYNC_STOP = 'THREADS_SYNC_STOP'
export const THREAD_UPDATE_BEGIN = 'THREAD_UPDATE_BEGIN'
export const THREAD_UPDATE_SUCCEEDED = 'THREAD_UPDATE_SUCCEEDED'
export const THREAD_UPDATE_FAILED = 'THREAD_UPDATE_FAILED'
export const THREAD_MARK_AS_READ_START = 'THREAD_MARK_AS_READ_START'
export const THREAD_MARK_AS_READ_STOP = 'THREAD_MARK_AS_READ_STOP'

export const MESSAGES_FETCH_BEGIN = 'MESSAGES_FETCH_BEGIN'
export const MESSAGES_FETCH_SUCCEEDED = 'MESSAGES_FETCH_SUCCEEDED'
export const MESSAGES_FETCH_FAILED = 'MESSAGES_FETCH_FAILED'
export const MESSAGES_SYNC_START = 'MESSAGES_SYNC_START'
export const MESSAGES_SYNC_STOP = 'MESSAGES_SYNC_STOP'
export const MESSAGE_CREATE_BEGIN = 'MESSAGE_CREATE_BEGIN'
export const MESSAGE_CREATE_SUCCEEDED = 'MESSAGE_CREATE_SUCCEEDED'
export const MESSAGE_CREATE_FAILED = 'MESSAGE_CREATE_FAILED'

export const NOTIFICATIONS_FETCH_BEGIN = 'NOTIFICATIONS_FETCH_BEGIN'
export const NOTIFICATIONS_FETCH_SUCCEEDED = 'NOTIFICATIONS_FETCH_SUCCEEDED'
export const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED'
export const NOTIFICATIONS_SYNC_START = 'NOTIFICATIONS_SYNC_START'
export const NOTIFICATIONS_SYNC_STOP = 'NOTIFICATIONS_SYNC_STOP'
export const NOTIFICATION_UPDATE_BEGIN = 'NOTIFICATION_UPDATE_BEGIN'
export const NOTIFICATION_UPDATE_SUCCEEDED = 'NOTIFICATION_UPDATE_SUCCEEDED'
export const NOTIFICATION_UPDATE_FAILED = 'NOTIFICATION_UPDATE_FAILED'

export const NOTIFICATION_MARK_ALL_AS_READ_BEGIN = 'NOTIFICATION_MARK_ALL_AS_READ_BEGIN'
export const NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED = 'NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED'
export const NOTIFICATION_MARK_ALL_AS_READ_FAILED = 'NOTIFICATION_MARK_ALL_AS_READ_FAILED'

export const AGENCY_NOTIFICATIONS_FETCH_BEGIN = 'AGENCY_NOTIFICATIONS_FETCH_BEGIN'
export const AGENCY_NOTIFICATIONS_FETCH_SUCCEEDED = 'AGENCY_NOTIFICATIONS_FETCH_SUCCEEDED'
export const AGENCY_NOTIFICATIONS_FETCH_FAILED = 'AGENCY_NOTIFICATIONS_FETCH_FAILED'
export const AGENCY_NOTIFICATIONS_SYNC_START = 'AGENCY_NOTIFICATIONS_SYNC_START'
export const AGENCY_NOTIFICATIONS_SYNC_STOP = 'AGENCY_NOTIFICATIONS_SYNC_STOP'
export const AGENCY_NOTIFICATION_UPDATE_BEGIN = 'AGENCY_NOTIFICATION_UPDATE_BEGIN'
export const AGENCY_NOTIFICATION_UPDATE_SUCCEEDED = 'AGENCY_NOTIFICATION_UPDATE_SUCCEEDED'
export const AGENCY_NOTIFICATION_UPDATE_FAILED = 'AGENCY_NOTIFICATION_UPDATE_FAILED'

export const AGENCY_NOTIFICATION_MARK_ALL_AS_READ_BEGIN = 'AGENCY_NOTIFICATION_MARK_ALL_AS_READ_BEGIN'
export const AGENCY_NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED = 'AGENCY_NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED'
export const AGENCY_NOTIFICATION_MARK_ALL_AS_READ_FAILED = 'AGENCY_NOTIFICATION_MARK_ALL_AS_READ_FAILED'

export const PAGE_FETCH_BEGIN = 'PAGE_FETCH_BEGIN'
export const PAGE_FETCH_SUCCEEDED = 'PAGE_FETCH_SUCCEEDED'
export const PAGE_FETCH_FAILED = 'PAGE_FETCH_FAILED'

export const REPORT_CREATE_BEGIN = 'REPORT_CREATE_BEGIN'
export const REPORT_CREATE_SUCCEEDED = 'REPORT_CREATE_SUCCEEDED'
export const REPORT_CREATE_FAILED = 'REPORT_CREATE_FAILED'

export const REPORT_FETCH_BEGIN = 'REPORT_FETCH_BEGIN'
export const REPORT_FETCH_SUCCEEDED = 'REPORT_FETCH_SUCCEEDED'
export const REPORT_FETCH_FAILED = 'REPORT_FETCH_FAILED'
export const REPORT_INVALIDATE = 'REPORT_INVALIDATE'

export const REPORT_TYPES_FETCH_BEGIN = 'REPORT_TYPES_FETCH_BEGIN'
export const REPORT_TYPES_FETCH_SUCCEEDED = 'REPORT_TYPES_FETCH_SUCCEEDED'
export const REPORT_TYPES_FETCH_FAILED = 'REPORT_TYPES_FETCH_FAILED'
export const REPORT_TYPES_INVALIDATE = 'REPORT_TYPES_INVALIDATE'

export const BUREAU_RESOURCES_COMPANIES_FETCH_BEGIN = 'BUREAU_RESOURCES_COMPANIES_FETCH_BEGIN'
export const BUREAU_RESOURCES_COMPANIES_FETCH_SUCCEEDED = 'BUREAU_RESOURCES_COMPANIES_FETCH_SUCCEEDED'
export const BUREAU_RESOURCES_COMPANIES_FETCH_FAILED = 'BUREAU_RESOURCES_COMPANIES_FETCH_FAILED'

export const SHIFT_BOOKING_UPDATE_BEGIN = 'SHIFT_BOOKING_UPDATE_BEGIN'
export const SHIFT_BOOKING_UPDATE_SUCCEEDED = 'SHIFT_BOOKING_UPDATE_SUCCEEDED'
export const SHIFT_BOOKING_UPDATE_FAILED = 'SHIFT_BOOKING_UPDATE_FAILED'

export const SHIFT_PATTERNS_FETCH_BEGIN = 'SHIFT_PATTERNS_FETCH_BEGIN'
export const SHIFT_PATTERNS_FETCH_SUCCEEDED = 'SHIFT_PATTERNS_FETCH_SUCCEEDED'
export const SHIFT_PATTERNS_FETCH_FAILED = 'SHIFT_PATTERNS_FETCH_FAILED'

export const DAILY_SHIFT_BOOKINGS_FETCH_SUCCEEDED = 'DAILY_SHIFT_BOOKINGS_FETCH_SUCCEEDED'

export const PAYMENT_SESSION_CREATE_BEGIN = 'PAYMENT_SESSION_CREATE_BEGIN'
export const PAYMENT_SESSION_CREATE_SUCCEEDED = 'PAYMENT_SESSION_CREATE_SUCCEEDED'
export const PAYMENT_SESSION_CREATE_FAILED = 'PAYMENT_SESSION_CREATE_FAILED'

export const PAYMENT_SESSION_VERIFY_BEGIN = 'PAYMENT_SESSION_VERIFY_BEGIN'
export const PAYMENT_SESSION_VERIFY_SUCCEEDED = 'PAYMENT_SESSION_VERIFY_SUCCEEDED'
export const PAYMENT_SESSION_VERIFY_FAILED = 'PAYMENT_SESSION_VERIFY_FAILED'

export const ROTAS_FETCH_BEGIN = 'ROTAS_FETCH_BEGIN'
export const ROTAS_FETCH_SUCCEEDED = 'ROTAS_FETCH_SUCCEEDED'
export const ROTAS_FETCH_FAILED = 'ROTAS_FETCH_FAILED'

export const ROTA_DOWNLOAD_BOOKING_FETCH_BEGIN = 'ROTA_DOWNLOAD_BOOKING_FETCH_BEGIN'
export const ROTA_DOWNLOAD_BOOKING_FETCH_SUCCEEDED = 'ROTA_DOWNLOAD_BOOKING_FETCH_SUCCEEDED'
export const ROTA_DOWNLOAD_BOOKING_FETCH_FAILED = 'ROTA_DOWNLOAD_BOOKING_FETCH_FAILED'

export const ROTA_COPY_CREATE_BEGIN = 'ROTA_COPY_CREATE_BEGIN'
export const ROTA_COPY_CREATE_SUCCEEDED = 'ROTA_COPY_CREATE_SUCCEEDED'
export const ROTA_COPY_CREATE_FAILED = 'ROTA_COPY_CREATE_FAILED'

export const ROTA_PUBLISH_CREATE_BEGIN = 'ROTA_PUBLISH_CREATE_BEGIN'
export const ROTA_PUBLISH_CREATE_SUCCEEDED = 'ROTA_PUBLISH_CREATE_SUCCEEDED'
export const ROTA_PUBLISH_CREATE_FAILED = 'ROTA_PUBLISH_CREATE_FAILED'

export const TRACK_EMAIL_LINK = 'TRACK_EMAIL_LINK'

export const NAVIGATION_REDIRECT = 'NAVIGATION_REDIRECT'

export const FORM_CHANGE = '@@redux-form/CHANGE'

export const EDIT_DELETE_SHIFT_DIALOG_OPEN = 'EDIT_DELETE_SHIFT_DIALOG_OPEN'

export const SET_SCREEN_READER_ALERT = 'SET_SCREEN_READER_ALERT'

export const JOB_SUGGESTION_ACCEPTED = 'JOB_SUGGESTION_ACCEPTED'
export const JOB_SUGGESTION_DISMISSED = 'JOB_SUGGESTION_DISMISSED'
export const JOB_SUGGESTION_REOPENED = 'JOB_SUGGESTION_REOPENED'

export const AUTH_LOGIN_TYPE_FETCH_BEGIN = 'AUTH_LOGIN_TYPE_FETCH_BEGIN'
export const AUTH_LOGIN_TYPE_FETCH_SUCCEEDED = 'AUTH_LOGIN_TYPE_FETCH_SUCCEEDED'
export const AUTH_LOGIN_TYPE_FETCH_FAILED = 'AUTH_LOGIN_TYPE_FETCH_FAILED'

export const AUTH_OAUTH_REDEEM_FETCH_BEGIN = 'AUTH_OAUTH_REDEEM_FETCH_BEGIN'
export const AUTH_OAUTH_REDEEM_FETCH_SUCCEEDED = 'AUTH_OAUTH_REDEEM_FETCH_SUCCEEDED'
export const AUTH_OAUTH_REDEEM_FETCH_FAILED = 'AUTH_OAUTH_REDEEM_FETCH_FAILED'

export const AUTH_INDEED_OAUTH_REDIRECT = 'AUTH_INDEED_OAUTH_REDIRECT'
export const AUTH_INDEED_LOGIN_BEGIN = 'AUTH_INDEED_LOGIN_BEGIN'
export const AUTH_INDEED_LOGIN_SUCEEDED = 'AUTH_INDEED_LOGIN_SUCEEDED'
export const AUTH_INDEED_LOGIN_FAILED = 'AUTH_INDEED_LOGIN_FAILED'

export const LOG_EXPERIMENT = 'LOG_EXPERIMENT'

export const AUTH_GET_BY_RESET_TOKEN = 'AUTH_GET_BY_RESET_TOKEN'
export const AUTH_SET_RESET_PASSWORD_TOKEN = 'AUTH_SET_RESET_PASSWORD_TOKEN'

export const AUTH_CREATE_WORKER_BEGIN = 'AUTH_CREATE_WORKER_BEGIN'
export const AUTH_CREATE_WORKER_SUCCEEDED = 'AUTH_CREATE_WORKER_SUCCEEDED'
export const AUTH_CREATE_WORKER_FAILED = 'AUTH_CREATE_WORKER_FAILED'

export const AGENCY_JOB_SHIFTS_FETCH_BEGIN = 'AGENCY_JOB_SHIFTS_FETCH_BEGIN'
export const AGENCY_JOB_SHIFTS_FETCH_SUCCEEDED = 'AGENCY_JOB_SHIFTS_FETCH_SUCCEEDED'
export const AGENCY_JOB_SHIFTS_FETCH_FAILED = 'AGENCY_JOB_SHIFTS_FETCH_FAILED'

export const AGENCY_JOB_SHIFT_UPDATE_BEGIN = 'AGENCY_JOB_SHIFT_UPDATE_BEGIN'
export const AGENCY_JOB_SHIFT_UPDATE_SUCCEEDED = 'AGENCY_JOB_SHIFT_UPDATE_SUCCEEDED'
export const AGENCY_JOB_SHIFT_UPDATE_FAILED = 'AGENCY_JOB_SHIFT_UPDATE_FAILED'

export const AGENCY_COST_CENTRE_CODES_FETCH_SUCCEEDED = 'AGENCY_COST_CENTRE_CODES_FETCH_SUCCEEDED'
export const AGENCY_COST_CENTRE_CODES_FETCH_FAILED = 'AGENCY_COST_CENTRE_CODES_FETCH_FAILED'
export const AGENCY_COST_CENTRE_CODES_FETCH_BEGIN = 'AGENCY_COST_CENTRE_CODES_FETCH_BEGIN'

export const COST_CENTER_CODES_FETCH_SUCCEEDED = 'COST_CENTER_CODES_FETCH_SUCCEEDED'
export const COST_CENTER_CODES_FETCH_FAILED = 'COST_CENTER_CODES_FETCH_FAILED'
export const COST_CENTER_CODES_FETCH_BEGIN = 'COST_CENTER_CODES_FETCH_BEGIN'

export const BANK_HOLIDAY_REGIONS_FETCH_BEGIN = 'BANK_HOLIDAY_REGIONS_FETCH_BEGIN'
export const BANK_HOLIDAY_REGIONS_FETCH_SUCCEEDED = 'BANK_HOLIDAY_REGIONS_FETCH_SUCCEEDED'
export const BANK_HOLIDAY_REGIONS_FETCH_FAILED = 'BANK_HOLIDAY_REGIONS_FETCH_FAILED'

export const OFFER_CONFIGS_FETCH_SUCCEEDED = 'OFFER_CONFIGS_FETCH_SUCCEEDED'
export const OFFER_CONFIGS_FETCH_FAILED = 'OFFER_CONFIGS_FETCH_FAILED'
export const OFFER_CONFIGS_FETCH_BEGIN = 'OFFER_CONFIGS_FETCH_BEGIN'

export const AGENCY_AGENT_VENUES_FETCH_BEGIN = 'AGENCY_AGENT_VENUES_FETCH_BEGIN'
export const AGENCY_AGENT_VENUES_FETCH_SUCCEEDED = 'AGENCY_AGENT_VENUES_FETCH_SUCCEEDED'
export const AGENCY_AGENT_VENUES_FETCH_FAILED = 'AGENCY_AGENT_VENUES_FETCH_FAILED'

export const EMPLOYER_MANAGERS_FETCH_BEGIN = 'EMPLOYER_MANAGERS_FETCH_BEGIN'
export const EMPLOYER_MANAGERS_FETCH_SUCCEEDED = 'EMPLOYER_MANAGERS_FETCH_SUCCEEDED'
export const EMPLOYER_MANAGERS_FETCH_FAILED = 'EMPLOYER_MANAGERS_FETCH_FAILED'

export const AUTH_ACCOUNT_SETTING_FETCH_BEGIN = 'AUTH_ACCOUNT_SETTING_FETCH_BEGIN'
export const AUTH_ACCOUNT_SETTING_FETCH_SUCCEEDED = 'AUTH_ACCOUNT_SETTING_FETCH_SUCCEEDED'
export const AUTH_ACCOUNT_SETTING_FETCH_FAILED = 'AUTH_ACCOUNT_SETTING_FETCH_FAILED'

export const EMPLOYER_SHIFT_RATE_TYPE_CREATE_BEGIN = 'EMPLOYER_SHIFT_RATE_TYPE_CREATE_BEGIN'
export const EMPLOYER_SHIFT_RATE_TYPE_CREATE_SUCCEEDED = 'EMPLOYER_SHIFT_RATE_TYPE_CREATE_SUCCEEDED'
export const EMPLOYER_SHIFT_RATE_TYPE_CREATE_FAILED = 'EMPLOYER_SHIFT_RATE_TYPE_CREATE_FAILED'

export const EMPLOYER_SHIFT_RATE_TYPE_UPDATE_BEGIN = 'EMPLOYER_SHIFT_RATE_TYPE_UPDATE_BEGIN'
export const EMPLOYER_SHIFT_RATE_TYPE_UPDATE_SUCCEEDED = 'EMPLOYER_SHIFT_RATE_TYPE_UPDATE_SUCCEEDED'
export const EMPLOYER_SHIFT_RATE_TYPE_UPDATE_FAILED = 'EMPLOYER_SHIFT_RATE_TYPE_UPDATE_FAILED'

export const EMPLOYER_SHIFT_RATE_TYPE_DELETE_BEGIN = 'EMPLOYER_SHIFT_RATE_TYPE_DELETE_BEGIN'
export const EMPLOYER_SHIFT_RATE_TYPE_DELETE_SUCCEEDED = 'EMPLOYER_SHIFT_RATE_TYPE_DELETE_SUCCEEDED'
export const EMPLOYER_SHIFT_RATE_TYPE_DELETE_FAILED = 'EMPLOYER_SHIFT_RATE_TYPE_DELETE_FAILED'

export const EMPLOYER_SHIFT_RATE_TYPES_FETCH_BEGIN = 'EMPLOYER_SHIFT_RATE_TYPES_FETCH_BEGIN'
export const EMPLOYER_SHIFT_RATE_TYPES_FETCH_SUCCEEDED = 'EMPLOYER_SHIFT_RATE_TYPES_FETCH_SUCCEEDED'
export const EMPLOYER_SHIFT_RATE_TYPES_FETCH_FAILED = 'EMPLOYER_SHIFT_RATE_TYPES_FETCH_FAILED'

export const PREFERRED_AGENCY_WORKER_CREATE_BEGIN = 'PREFERRED_AGENCY_WORKER_CREATE_BEGIN'
export const PREFERRED_AGENCY_WORKER_CREATE_SUCCEEDED = 'PREFERRED_AGENCY_WORKER_CREATE_SUCCEEDED'
export const PREFERRED_AGENCY_WORKER_CREATE_FAILED = 'PREFERRED_AGENCY_WORKER_CREATE_FAILED'

export const PREFERRED_AGENCY_WORKER_DELETE_BEGIN = 'PREFERRED_AGENCY_WORKER_DELETE_BEGIN'
export const PREFERRED_AGENCY_WORKER_DELETE_SUCCEEDED = 'PREFERRED_AGENCY_WORKER_DELETE_SUCCEEDED'
export const PREFERRED_AGENCY_WORKER_DELETE_FAILED = 'PREFERRED_AGENCY_WORKER_DELETE_FAILED'

export const OVERTIME_RULES_FETCH_BEGIN = 'OVERTIME_RULES_FETCH_BEGIN'
export const OVERTIME_RULES_FETCH_SUCCEEDED = 'OVERTIME_RULES_FETCH_SUCCEEDED'
export const OVERTIME_RULES_FETCH_FAILED = 'OVERTIME_RULES_FETCH_FAILED'

export const TOGGLE_IDLING = 'TOGGLE_IDLING'

export const SCHEDULED_AGENCIES_FETCH_BEGIN = 'SCHEDULED_AGENCIES_FETCH_BEGIN'
export const SCHEDULED_AGENCIES_FETCH_SUCCEEDED = 'SCHEDULED_AGENCIES_FETCH_SUCCEEDED'
export const SCHEDULED_AGENCIES_FETCH_FAILED = 'SCHEDULED_AGENCIES_FETCH_FAILED'

/** MARKER FOR ACTION-TYPES GENERATOR */

// See #allRequestTypesOfAction
export const allRequestTypesOfActionType = actionType => {
  const actionTypeParts = actionType.split('_')
  const baseActionType = initial(actionTypeParts).join('_')
  const withSuffix = suffix => [baseActionType, suffix].join('_')
  return {
    begin: withSuffix('BEGIN'),
    succeeded: withSuffix('SUCCEEDED'),
    failed: withSuffix('FAILED'),
  }
}

// For any action creator of type `*_{BEGIN,SUCCEEDED,FAILED}`, return all three
// action types.
//
// @example
// ```
//  import { createAction } from 'redux-actions'
//  const createVenue = createAction(VENUE_CREATE_BEGIN)
//  const actionTypes = allRequestTypesOfAction(createVenue)
//  // will result in actionTypes being:
//  {
//    begin: VENUE_CREATE_BEGIN
//    succeeded: VENUE_CREATE_SUCCEEDED
//    failed: VENUE_CREATE_FAILED
//  }
// ```
//
// @param {Function} actionCreator Action creator created using redux-actions
// @returns {{ begin: String, succeeded: String, failed: String }} action types
export const allRequestTypesOfAction = actionCreator => allRequestTypesOfActionType(actionCreator.toString())
