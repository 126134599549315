import { REHYDRATE } from 'redux-persist'
import { get } from 'lodash'

import * as types from '../actions/action-types'

const initialState = {
  showDashboardJoyrideTour: true,
  showRotaJoyrideTour: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DASHBOARD_JOYRIDE_TOUR_PASSED:
      return {
        ...state,
        showDashboardJoyrideTour: false,
      }
    case types.SET_ROTA_JOYRIDE_TOUR_PASSED:
      return {
        ...state,
        showRotaJoyrideTour: false,
      }
    case REHYDRATE:
      return get(action, 'payload.joyride') || initialState
    default:
      return state
  }
}

export default reducer
